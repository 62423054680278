import React, { Component } from 'react'
import ContentEditable from 'react-contenteditable'
import Dropzone from 'react-dropzone'
import ptBR from 'date-fns/locale/pt-BR'
import { format, differenceInYears } from 'date-fns'
import { GithubPicker } from 'react-color'
import { withFirebase } from '../../firebase/context'
import FormInlineError from '../../helpers/FormInlineError'
import { randomString } from '../../helpers/UIHelpers'

class MemorialHeader extends Component {
  constructor(props) {
    super(props)
    this.contentEditable = React.createRef()
    const { isPreview, memorialIntroduction, personBirthday, personDeathday, personPhoto } = props
    this.state = {
      memorialIntroduction: memorialIntroduction || '',
      personPhoto: isPreview ? '' : personPhoto,
      personBirthday,
      personDeathday,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...nextProps,
    })
  }

  handleMemorialIntroductionChange = evt => {
    const { handleMemorialIntroductionChange } = this.props
    this.setState({
      memorialIntroduction: evt.target.value,
    })
    handleMemorialIntroductionChange(evt.target.value)
  }

  signGuestBook = () => {
    const { signGuestBook } = this.props
    signGuestBook()
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    const reader = new FileReader()
    const $this = this
    const { handlePersonPhotoChange, personId, firebase, updateStore } = $this.props
    const { storage } = firebase

    const uploadImage = image =>
      new Promise(resolve => {
        if (!image) {
          resolve()
        }
        const storageRef = storage.ref()
        const imagePath = `site/memorials/${personId}/${randomString(20)}`
        const imageRef = storageRef.child(imagePath)
        imageRef.put(image).then(snapshot => {
          storageRef
            .child(imagePath)
            .getDownloadURL()
            .then(url => {
              resolve(url)
            })
        })
      })

    reader.onload = e => {
      $this.setState({
        personPhoto: e.target.result,
        imageFile: acceptedFiles[0],
      })
      handlePersonPhotoChange(e.target.result)
      uploadImage(acceptedFiles[0]).then(url => {
        updateStore({
          personPhoto: url,
        })
      })
    }
    reader.readAsDataURL(acceptedFiles[0])
  }

  render = () => {
    const {
      handleHeaderBackgroundColorChange,
      headerBackgroundColor,
      isEditing,
      isPreview,
      name,
      shouldShowImageError,
      shouldShowMemorialIntroductionError,
    } = this.props
    let { personAge } = this.props
    const { memorialIntroduction, personBirthday, personDeathday, personPhoto } = this.state
    const personBirthdayFormatted = format(new Date(personBirthday), `dd'/'MM'/'yyyy`, {
      awareOfUnicodeTokens: true,
      locale: ptBR,
    })
    const personDeathdayFormatted = format(new Date(personDeathday), `dd'/'MM'/'yyyy`, {
      awareOfUnicodeTokens: true,
      locale: ptBR,
    })
    personAge = differenceInYears(new Date(personDeathday), new Date(personBirthday))

    return (
      <header
        className={`relative z-0 pt4 pb5 pv5-m pv6-l
          ${isPreview ? 'pb2 ph3' : 'overflow-hidden pb5'}
          ${isEditing ? 'overflow-hidden' : ''}
        `}
        style={{
          backgroundColor: headerBackgroundColor,
        }}
      >
        <div id="stars" />
        <div id="stars2" />
        <div id="stars3" />
        <div className="w-100 mw8-ns ph3 center">
          <div className="flex flex-wrap tl w-100 relative z-max mt5 mt3-m mt0-l">
            <div data-id="memorial-header-wrapper" className="w-100 w-75-ns br3 br--top bg-white">
              <div className="flex flex-wrap flex-nowrap-ns">
                <div className="w-100 w-auto-ns">
                  <div className="flex justify-center">
                    <div className="bg-dark-gray db h5 w5 nt5 nl3-m nl5-l flex items-center justify-center br-pill overflow-hidden">
                      {isPreview ? (
                        <div
                          className="h-100 cover bg-center"
                          style={{
                            backgroundImage: `url(${personPhoto})`,
                          }}
                        >
                          <Dropzone accept="image/*" onDrop={this.onDrop} multiple={false}>
                            {({ getRootProps, getInputProps, isDragActive }) => (
                              <div
                                {...getRootProps()}
                                className={`relative pointer br-pill b--dashed bw1 outline-0 h-100 flex items-center lh-title white tc ph3 ${
                                  isDragActive ? 'b--red' : ''
                                } ${shouldShowImageError ? 'b--red' : 'b--mid-gray'}`}
                              >
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                  <div className="w-100">Solte seu arquivo aqui...</div>
                                ) : (
                                  <div className="w-100 o-70">
                                    <div className="material-icons w-100 f4 f1-ns mb0">
                                      add_a_photo
                                    </div>
                                    <p className="ma0 fw3 f7 f6-ns">{`Clique aqui para adicionar uma foto de ${name}`}</p>
                                  </div>
                                )}
                                {shouldShowImageError && (
                                  <FormInlineError error="sss" position="top" />
                                )}
                              </div>
                            )}
                          </Dropzone>
                        </div>
                      ) : (
                        <div
                          className="w-100 h-100 cover bg-center"
                          style={{
                            backgroundImage: `url(${personPhoto})`,
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="tc">
                    <button
                      type="button"
                      className={`tc center ma3 w-auto btn btn-primary btn-small outline-0 ${
                        isPreview ? 'o-0' : ''
                      }`}
                      onClick={this.signGuestBook}
                    >
                      Livro de Visitas
                    </button>
                  </div>
                </div>
                <div className="w-100 pa3 tc tl-ns">
                  <p className="ma0 gray f7 lh-solid">Memorial de</p>
                  <h1 className="ma0">{name}</h1>
                  {isPreview ? (
                    <ContentEditable
                      innerRef={this.contentEditable}
                      html={memorialIntroduction}
                      placeholder="Clique aqui e adicione o texto introdutório do memorial."
                      disabled={false}
                      onChange={this.handleMemorialIntroductionChange}
                      tagName="article"
                      style={{ minHeight: 'auto' }}
                      className={`mt2 h-auto b--dashed bw1 fw3 pa2 pointer lh-copy outline-0 ${
                        shouldShowMemorialIntroductionError ? 'b--red' : 'b--mid-gray '
                      }`}
                    />
                  ) : (
                    <p className="lh-copy dark-gray">{memorialIntroduction}</p>
                  )}
                </div>
              </div>
            </div>
            <div
              data-id="memorial-header-wrapper-age"
              className="w-100 w-25-ns bg-white-80 pa3 flex items-center justify-center br3 br--bottom overflow-hidden"
            >
              <div className="f7 gray tc lh-title">
                {`${personBirthdayFormatted} · ${personDeathdayFormatted}`}
                {personAge > 50 && <div className="mt2 o-50">{`${personAge} anos`}</div>}
              </div>
            </div>
          </div>
        </div>
        {isPreview && (
          <div>
            <div className="f6 center mt2 dib pa2 b--dashed bw1 b--white">
              Escolha a cor de fundo do cabeçalho
            </div>
            <div
              className="flex justify-center mt2 absolute left-0 right-0"
              style={{
                pointerEvents: 'none',
              }}
            >
              <GithubPicker
                color={headerBackgroundColor}
                colors={[
                  '#ffffff',
                  '#dadedf',
                  '#ffb091',
                  '#f6c4c4',
                  '#f5f6c4',
                  '#e0f6c4',
                  '#dfc4f6',
                  '#cfd414',
                  '#c4f6f2',
                  '#c4def6',
                  '#9e91ff',
                  '#29a550',
                  '#296ba5',
                  '#000000',
                ]}
                onChangeComplete={handleHeaderBackgroundColorChange}
              />
            </div>
          </div>
        )}
        <div className="addthis_inline_share_toolbox tc mt3" />
      </header>
    )
  }
}

export default withFirebase(MemorialHeader)
