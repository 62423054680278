import React, { Component } from 'react'

import getFirebase from '../firebase/firebase'

class Promos extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasSubmitted: false,
      isValid: false,
      promoCode: '',
    }
  }

  handleApplyPromo(newPrice) {
    const { handleApplyPromo } = this.props
    handleApplyPromo(newPrice)
  }

  applyPromo() {
    const { promoCode } = this.state
    const { price } = this.props
    const verifyPromo = () => {
      const { firestore } = getFirebase()
      return new Promise(resolve => {
        firestore
          .collection('Promos')
          .where('code', '==', promoCode.toUpperCase())
          .where('available', '==', true)
          .get()
          .then(promoDoc => {
            resolve(promoDoc)
          })
      })
    }

    this.setState({
      hasSubmitted: true,
    })
    if (promoCode.trim() !== '') {
      verifyPromo().then(promoDoc => {
        if (!promoDoc.empty) {
          const newPrice = parseFloat(price * (promoDoc.docs[0].data().discount / 100))
          this.handleApplyPromo(newPrice)
          this.setState({
            isValid: true,
          })
        } else {
          this.setState({
            isValid: false,
          })
        }
      })
    }
  }

  render() {
    const { hasSubmitted, isValid, promoCode } = this.state
    return (
      <div className={`w-100 input relative ${!isValid && hasSubmitted ? 'has-error' : ''}`}>
        <input
          autoComplete="off"
          value={promoCode}
          onChange={e => {
            this.setState({
              promoCode: e.target.value.toUpperCase(),
            })
          }}
          id="promocode"
          type="text"
          className={`ttu ${promoCode !== '' ? 'filled' : ''}`}
        />
        <label htmlFor="promocode">Código promocional</label>
        <button
          type="button"
          className="mt1 mr1 btn btn-primary btn-small absolute right-0"
          onClick={() => this.applyPromo()}
        >
          Cadastrar
        </button>
        {hasSubmitted && !isValid && (
          <div className="red mt4 pt3 f7 tl">Código promocional inválido.</div>
        )}
        {hasSubmitted && isValid && (
          <div className="green mt4 pt3 f7 tl">
            <span className="b">Desconto de 50%</span> ativado!
          </div>
        )}
      </div>
    )
  }
}

export default Promos
