import React, { Component } from 'react'
import StepZilla from 'react-stepzilla'

import SEO from '../components/seo'
import Step1 from '../components/Memorials/Step1'
import Step2 from '../components/Memorials/Step2'
import Step3 from '../components/Memorials/Step3'
import Step4 from '../components/Memorials/Step4'
import { AuthUserContext } from '../components/Session'

class CreateMemorial extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.sampleStore = {
      personCity: '',
      personName: '',
      personBirthday: null,
      personDeathday: null,
      userName: '',
      userEmail: '',
      savedToCloud: false,
    }
  }

  getStore() {
    return this.sampleStore
  }

  updateStore(update) {
    this.sampleStore = {
      ...this.sampleStore,
      ...update,
    }
  }

  render() {
    const steps = [
      {
        name: 'Homenageado',
        component: (
          <Step1
            getStore={() => this.getStore()}
            updateStore={u => {
              this.updateStore(u)
            }}
          />
        ),
      },
      {
        name: 'Cabeçalho',
        component: (
          <Step2
            getStore={() => this.getStore()}
            updateStore={u => {
              this.updateStore(u)
            }}
          />
        ),
      },
      {
        name: 'Conteúdo',
        component: (
          <Step3
            getStore={() => this.getStore()}
            updateStore={u => {
              this.updateStore(u)
            }}
          />
        ),
      },
      {
        name: 'Visualização',
        component: (
          <Step4
            getStore={() => this.getStore()}
            updateStore={u => {
              this.updateStore(u)
            }}
          />
        ),
      },
    ]
    return (
      <>
        <SEO title="Criação de Memorial" />
        <section className="tc ph2 w-100 mb2 mb3-m mb4-l">
          <div className="mw8-ns center ph2">
            <h1 className="f5 f4-m f3-l mv4">Criação de Memorial</h1>
            <div className="step-progress mt4">
              <AuthUserContext.Consumer>
                {authUser => (
                  <StepZilla
                    steps={steps}
                    stepsNavigation={false}
                    preventEnterSubmission
                    dontValidate={false}
                    onStepChange={step => window.sessionStorage.setItem('step', step)}
                    startAtStep={0}
                    authUser={authUser}
                  />
                )}
              </AuthUserContext.Consumer>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default CreateMemorial
