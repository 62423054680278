import React, { Component } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { injectIntl } from 'react-intl'
import Alert from 'react-s-alert'

import 'react-s-alert/dist/s-alert-default.css'
import 'react-s-alert/dist/s-alert-css-effects/slide.css'

import GuestbookEntry from './templates/GuestbookEntry'
import { AuthUserContext } from '../Session'
import PaypalButton from '../Payments/PayPalButton'
import MemorialHeader from './templates/MemorialHeader'
import MemorialNavBar from './templates/NavBar'
import Promos from '../Promos'

import { withFirebase } from '../firebase/context'

import {
  FuneralInfo,
  ImageAndMessage,
  ImageOnly,
  MessageOnly,
  QuoteAndMessage,
  YoutubeVideo,
} from '../Templates'

class Step4 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      action: 'selectPostType',
      activeTab: 'memories',
      available: false,
      eventDay: 0,
      eventMonth: 0,
      eventYear: 0,
      guests: [],
      memorialBackgroundColor: props.getStore().memorialBackgroundColor,
      memorialIntroduction: props.getStore().memorialIntroduction,
      personBirthday: props.getStore().personBirthday,
      personDeathday: props.getStore().personDeathday,
      personName: props.getStore().personName,
      personPhoto: props.getStore().personPhoto,
      postType: '',
      price: 0,
      socialEntryUrl: '',
      tributes: [],
    }
    this.handleApplyPromo = this.handleApplyPromo.bind(this)
    this.handleDeleteTribute = this.handleDeleteTribute.bind(this)
    this.handleEditTribute = this.handleEditTribute.bind(this)
    this.handleApproveGuestbookEntry = this.handleApproveGuestbookEntry.bind(this)
    this.handleDeleteGuestbookEntry = this.handleDeleteGuestbookEntry.bind(this)
    this.handleHeaderBackgroundColorChange = this.handleHeaderBackgroundColorChange.bind(this)
    this.handleMemorialIntroductionChange = this.handleMemorialIntroductionChange.bind(this)
    this.handlePersonPhotoChange = this.handlePersonPhotoChange.bind(this)
    this.handleTabChange = this.handleTabChange.bind(this)
  }

  componentDidMount() {
    this.loadMemorialData()
    const { intl } = this.props
    const { formatMessage } = intl
    const price = formatMessage({ id: 'common:memorial_price' })
    this.setState({
      price: parseFloat(price),
    })
  }

  handleHeaderBackgroundColorChange = color => {
    const { updateStore } = this.props
    this.setState({
      memorialBackgroundColor: color.hex,
    })
    updateStore({
      memorialBackgroundColor: color.hex,
    })
  }

  handleMemorialIntroductionChange = memorialIntroduction => {
    const { updateStore } = this.props
    this.setState({ memorialIntroduction })
    updateStore({ memorialIntroduction })
  }

  handleEditTribute = (postType, params) => {
    const { jumpToStep, updateStore } = this.props
    const { tributeIndex } = params
    updateStore({
      action: 'editContent',
      postType,
      tributeIndex,
      ...params,
    })
    jumpToStep(0)
  }

  handleApproveGuestbookEntry = guestbookEntryId => {
    const { firebase } = this.props
    const { firestore } = firebase
    const guestbookEntryRef = firestore.collection('Guests').doc(guestbookEntryId)
    guestbookEntryRef.set({ approved: true }, { merge: true }).then(() => {
      confirmAlert({
        customUI: ({ onClose }) => (
          <div className="fixed top-0 bg-black-30 left-0 w-100 h-100 flex items-center justify-center sans-serif">
            <div className="box pa3 w-90 w-50-m w-40-l tc">
              <p className="mb4">Mensagem aprovada. Em breve ela será publicada no memorial.</p>
              <div className="flex w-100 flex-wrap justify-between">
                <button
                  type="button"
                  className="mh2 w-100 w-auto-ns mr2-ns btn btn-primary mb2 mb0-ns"
                  onClick={() => {
                    onClose()
                    window.location.reload(true)
                  }}
                >
                  Continuar
                </button>
              </div>
            </div>
          </div>
        ),
      })
    })
  }

  handleDeleteGuestbookEntry = guestbookEntryId => {
    const { firebase, getStore } = this.props
    const { firestore } = firebase
    const data = getStore()
    confirmAlert({
      customUI: ({ onClose }) => (
        <div className="fixed top-0 bg-black-30 left-0 w-100 h-100 flex items-center justify-center sans-serif">
          <div className="box pa3 w-90 w-50-m w-40-l tc">
            <h1>Excluir Mensagem?</h1>
            <p className="mb4">Ao prosseguir, a mensagem será apagada.</p>
            <div className="flex w-100 flex-wrap justify-between">
              <button
                type="button"
                className="mh2 w-100 w-auto-ns mr2-ns btn btn-primary mb2 mb0-ns"
                onClick={() => {
                  const guestbookEntryRef = firestore.collection('Guests').doc(guestbookEntryId)
                  const memorialRef = firestore.collection('Memorials').doc(data.memorialId)
                  const batch = firestore.batch()
                  batch.delete(guestbookEntryRef)
                  memorialRef.get().then(memorialDoc => {
                    let obj = memorialDoc.get('guests')
                    obj = obj.filter(guestbookEntry => guestbookEntry.id !== guestbookEntryId)
                    batch.set(memorialRef, { guests: obj }, { merge: true })
                    batch.commit().then(() => {
                      onClose()
                      window.location.reload(true)
                    })
                  })
                }}
              >
                Sim
              </button>
              <button
                type="button"
                className="mh2 w-100 w-auto-ns ml2-ns btn btn-primary-outline mb2 mb0-ns"
                onClick={onClose}
              >
                Não
              </button>
            </div>
          </div>
        </div>
      ),
    })
  }

  handleApplyPromo = newPrice => {
    this.setState({
      price: newPrice,
    })
  }

  handleDeleteTribute = tributeIndex => {
    const { firebase, getStore } = this.props
    const { tributes } = this.state
    const data = getStore()
    const { firestore } = firebase
    confirmAlert({
      customUI: ({ onClose }) => (
        <div className="fixed top-0 bg-black-30 left-0 w-100 h-100 flex items-center justify-center sans-serif">
          <div className="box pa3 w-90 w-50-m w-40-l tc">
            <h1>Prosseguir?</h1>
            <p className="mb4">Tem certeza que deseja excluir este tributo?</p>
            <div className="flex w-100 flex-wrap justify-between">
              <button
                type="button"
                className="mh2 w-100 w-auto-ns mr2-ns btn btn-primary mb2 mb0-ns"
                onClick={() => {
                  const docRef = firestore.collection('Memorials').doc(data.memorialId)
                  firestore.runTransaction(t =>
                    t
                      .get(docRef)
                      .then(doc => {
                        const obj = doc.get('tributes') ? doc.get('tributes') : []
                        obj.splice(tributeIndex, 1)
                        t.set(docRef, { tributes: obj }, { merge: true })
                      })
                      .then(result => {
                        const filtered = tributes.filter(
                          (value, index, arr) => tributeIndex !== index
                        )
                        this.setState({
                          tributes: filtered,
                        })
                      })
                  )
                  onClose()
                }}
              >
                Sim
              </button>
              <button
                type="button"
                className="mh2 w-100 w-auto-ns ml2-ns btn btn-primary-outline mb2 mb0-ns"
                onClick={onClose}
              >
                Não
              </button>
            </div>
          </div>
        </div>
      ),
    })
  }

  loadMemorialData = () => {
    const { firebase, getStore, updateStore } = this.props
    const $this = this
    const { memorialId } = getStore()
    const { firestore } = firebase
    const firestoreSettings = {
      timestampsInSnapshots: true,
    }
    firestore.settings(firestoreSettings)
    const memorialRef = firestore.collection('Memorials').doc(memorialId)
    const promises = []
    return new Promise(resolve => {
      let memorialData = {}
      const guestbookEntries = []
      memorialRef.get().then(doc => {
        const {
          available,
          guests,
          memorialBackgroundColor,
          memorialIntroduction,
          person,
          personPhoto,
          socialPosts,
          tributes,
        } = doc.data()

        $this.setState({ available })
        const personId = person.id

        firestore
          .collection('People')
          .doc(personId)
          .get()
          .then(personDoc => {
            const { personName, birthday, deathday } = personDoc.data()
            memorialData = {
              available,
              memorialBackgroundColor,
              memorialIntroduction,
              personBirthday: birthday.toDate(),
              personDeathday: deathday.toDate(),
              personName,
              personPhoto,
              socialPosts,
              tributes,
            }
            guests.map((guestbookEntryRef, index) =>
              promises.push(
                guestbookEntryRef.get().then(guestbookEntry => {
                  const guestbookEntryData = guestbookEntry.data()
                  guestbookEntryData.id = guestbookEntry.id
                  promises.push(
                    guestbookEntryData.user.get().then(guestbookEntryUser => {
                      guestbookEntryData.user = {
                        name: guestbookEntryUser.data().name,
                        socialId: guestbookEntryUser.data().socialId,
                      }
                      guestbookEntries.push(guestbookEntryData)
                    })
                  )
                })
              )
            )
            return Promise.all(promises)
          })
          .then(() => {
            memorialData.guests = guestbookEntries
            $this.setState(memorialData)
            updateStore(memorialData)
            resolve(memorialData)
          })
      })
    })
  }

  persistData = e => {
    const { firebase, getStore } = this.props
    const data = getStore()
    const { firestore } = firebase
    const memorialRef = firestore.collection('Memorials').doc(data.memorialId)

    firestore.runTransaction(t =>
      t
        .get(memorialRef)
        .then(doc => {
          const personId = doc.data().person.id
          t.set(memorialRef, { available: true }, { merge: true })
          const personRef = firestore.collection('People').doc(personId)
          t.set(personRef, { available: true }, { merge: true })
          this.setState({ available: true })
        })
        .then(result => {
          if (typeof window !== 'undefined' && window.fbq) {
            window.fbq('track', 'Purchase', {
              value: 199.9,
              currency: 'BRL',
            })
          }
          confirmAlert({
            customUI: ({ onClose }) => (
              <div className="fixed top-0 bg-black-30 left-0 w-100 h-100 flex items-center justify-center sans-serif">
                <div className="box pa3 w-90 w-50-m w-40-l tc">
                  <h1 className="f4 mb3">Memorial publicado com sucesso.</h1>
                  <p className="lh-copy mb3">
                    Aguarde alguns minutos para sua disponibilização no site. Para acessá-lo, basta
                    buscar por&nbsp;
                    <b>{data.personName}</b>
                    &nbsp;na&nbsp;
                    <a href="/" className="blue">
                      página inicial
                    </a>
                    &nbsp;do Immortalis.
                  </p>
                  <div className="flex w-100 flex-wrap justify-center">
                    <button
                      type="button"
                      className="mh2 w-100 w-auto-ns mr2-ns btn btn-primary mb2 mb0-ns"
                      onClick={() => {
                        onClose()
                      }}
                    >
                      Fechar
                    </button>
                  </div>
                </div>
              </div>
            ),
          })
        })
    )
  }

  saveHeader = () => {
    const { firebase, getStore } = this.props
    const data = getStore()
    const { firestore } = firebase
    const docRef = firestore.collection('Memorials').doc(data.memorialId)
    firestore.runTransaction(t =>
      t
        .get(docRef)
        .then(() => {
          t.set(
            docRef,
            {
              memorialBackgroundColor: data.memorialBackgroundColor,
              memorialIntroduction: data.memorialIntroduction,
              personPhoto: data.personPhoto,
            },
            { merge: true }
          )
        })
        .then(result => {
          confirmAlert({
            customUI: ({ onClose }) => (
              <div className="fixed top-0 bg-black-30 left-0 w-100 h-100 flex items-center justify-center sans-serif">
                <div className="box pa3 w-90 w-50-m w-40-l tc">
                  <h1 className="f4 mb4">Cabeçalho alterado com sucesso.</h1>
                  <div className="flex w-100 flex-wrap justify-center">
                    <button
                      type="button"
                      className="mh2 w-100 w-auto-ns mr2-ns btn btn-primary mb2 mb0-ns"
                      onClick={() => {
                        onClose()
                      }}
                    >
                      Fechar
                    </button>
                  </div>
                </div>
              </div>
            ),
          })
        })
    )
  }

  addToMemorial = e => {
    const { firebase, getStore } = this.props
    const { firestore } = firebase
    const { eventYear, eventMonth, eventDay, personBirthday, postType } = this.state
    const firestoreSettings = {
      timestampsInSnapshots: true,
    }
    let eventDate = new Date(eventYear, eventMonth === 0 ? 1 : eventMonth - 1, eventDay)
    if (eventDate.getFullYear() < personBirthday.getFullYear()) {
      eventDate = null
    }
    firestore.settings(firestoreSettings)
    e.preventDefault()
    if (this.isValidated()) {
      const data = getStore()
      const memorialItemData = {
        dateCreated: new Date(),
        eventDate,
        eventDay,
        eventMonth,
        eventYear,
        image: data.image || '',
        message: data.message || '',
        quote: data.quote || '',
        quoteBackgroundColor: data.quoteBackgroundColor || '',
        quoteBackgroundImage: data.quoteBackgroundImage || '',
        quoteFontColor: data.quoteFontColor || '',
        type: postType,
        venueAddress: data.venueAddress || '',
        venueDateTime: data.venueDateTime || null,
        venueName: data.venueName || '',
        videoUrl: data.videoUrl || '',
      }

      const addItemToMemorial = itemData =>
        new Promise((resolve, reject) => {
          const docRef = firestore.collection('Memorials').doc(data.memorialId)
          firestore.runTransaction(t =>
            t
              .get(docRef)
              .then(doc => {
                const obj = doc.get('tributes') ? doc.get('tributes') : []
                obj.push(itemData)
                t.set(docRef, { tributes: obj }, { merge: true })
              })
              .then(result => {
                resolve(result)
              })
          )
        })

      addItemToMemorial(memorialItemData).then(memorialTributeKey => {
        // console.log(`Item added: ${memorialTributeKey} `)
        this.setState({
          action: 'contentAdded',
        })
      })
    }
  }

  handlePersonPhotoChange = personPhoto => {
    this.setState({ personPhoto })
  }

  handleTabChange = activeTab => {
    this.setState({ activeTab })
  }

  addSocialEntry = () => {
    const { firebase, getStore } = this.props
    const { socialEntryUrl } = this.state
    const data = getStore()
    const { firestore } = firebase
    const docRef = firestore.collection('Memorials').doc(data.memorialId)
    let socialPosts = []
    firestore.runTransaction(t =>
      t
        .get(docRef)
        .then(doc => {
          socialPosts = doc.get('socialPosts') ? doc.get('socialPosts') : []
          socialPosts.push(socialEntryUrl)
          t.set(docRef, { socialPosts }, { merge: true })
        })
        .then(result => {
          this.setState({
            socialEntryUrl: '',
          })
          Alert.success('Homenagem pública adicionada com sucesso', {
            effect: 'stackslide',
            position: 'bottom-left',
          })
          setTimeout(() => {
            Alert.closeAll()
          }, 3000)
        })
    )
  }

  render() {
    const {
      action,
      activeTab,
      available,
      guests,
      memorialBackgroundColor,
      memorialIntroduction,
      personBirthday,
      personDeathday,
      personName,
      personPhoto,
      price,
      socialEntryUrl,
      tributes,
    } = this.state

    const { intl, jumpToStep, updateStore } = this.props
    const { formatMessage } = intl

    // formatMessage({ id: 'common:memorial_price' })

    const shouldShowUnapprovedGuestbookEntries =
      guests.filter(guestbook => !guestbook.approved).length > 0
    const shouldShowApprovedGuestbookEntries =
      guests.filter(guestbook => guestbook.approved).length > 0
    return (
      <section className="box tc ph2 w-100 pa3 pa4-ns mb3">
        <Alert />
        <div>
          <div className="flex flex-wrap w-100 center relative">
            <h2 className="ma0 mb3 mb4-ns fw5 f4 tc w-100 lh-title ph3 f5 f4-ns">
              Visualize o Memorial
            </h2>
            <button
              type="button"
              className="btn btn-primary btn-small lh-title w-100 w-auto-ns absolute right-0"
              onClick={() => {
                this.saveHeader()
              }}
            >
              Salvar Cabeçalho
            </button>
          </div>
          <div>
            <MemorialHeader
              action={action}
              handleHeaderBackgroundColorChange={this.handleHeaderBackgroundColorChange}
              handleMemorialIntroductionChange={this.handleMemorialIntroductionChange}
              handlePersonPhotoChange={this.handlePersonPhotoChange}
              headerBackgroundColor={memorialBackgroundColor}
              isPreview
              isEditing
              memorialIntroduction={memorialIntroduction || ''}
              name={personName}
              personBirthday={personBirthday}
              personDeathday={personDeathday}
              personPhoto={personPhoto}
              updateStore={updateStore}
            />
          </div>
          <section className="mb3">
            <AuthUserContext.Consumer>
              {authUser => {
                const isAdmin = authUser && authUser.roles.includes('admin')
                return (
                  <MemorialNavBar
                    handleTabChange={this.handleTabChange}
                    isEditing
                    shouldShowPublicTributes={isAdmin}
                  />
                )
              }}
            </AuthUserContext.Consumer>
          </section>
          <div className="mt4 center">
            {activeTab === 'timeline' && (
              <div className="mt4 ba br3 b--mid-gray ph3 ph6-ns pv4 pv7-ns lh-copy">
                {/* eslint-disable */}
                Aqui será disponibilizada sua <strong>Linha do tempo</strong>,
                onde os tributos aos quais você adicionou uma data serão listados
                do mais antigo para o mais recente.
                {/* eslint-enable */}
              </div>
            )}
            {activeTab === 'memories' && (
              <div>
                <div className="masonry">
                  {tributes.map((tribute, index) => {
                    const {
                      eventDay,
                      eventMonth,
                      eventYear,
                      eventDate,
                      image,
                      message,
                      quote,
                      quoteBackgroundColor,
                      quoteBackgroundImage,
                      quoteFontColor,
                      type,
                      venueAddress,
                      venueDateTime,
                      venueName,
                      videoUrl,
                    } = tribute
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={index} className="masonry-item relative w-100 pa2 ma0">
                        <div className="">
                          {type === 'FuneralInfo' && (
                            <FuneralInfo
                              allowEdit
                              handleDeleteTribute={this.handleDeleteTribute}
                              handleEditTribute={this.handleEditTribute}
                              message={message}
                              tributeIndex={index}
                              type="memorial"
                              venueAddress={venueAddress}
                              venueDateTime={venueDateTime.toDate()}
                              venueName={venueName}
                            />
                          )}
                          {type === 'ImageAndMessage' && (
                            <ImageAndMessage
                              allowEdit
                              eventDate={eventDate}
                              eventDay={eventDay}
                              eventMonth={eventMonth}
                              eventYear={eventYear}
                              handleDeleteTribute={this.handleDeleteTribute}
                              handleEditTribute={this.handleEditTribute}
                              image={image}
                              message={message}
                              tributeIndex={index}
                              type="memorial"
                            />
                          )}
                          {type === 'ImageOnly' && (
                            <ImageOnly
                              allowEdit
                              handleDeleteTribute={this.handleDeleteTribute}
                              image={image}
                              tributeIndex={index}
                            />
                          )}
                          {type === 'MessageOnly' && (
                            <MessageOnly
                              allowEdit
                              eventDate={eventDate}
                              eventDay={eventDay}
                              eventMonth={eventMonth}
                              eventYear={eventYear}
                              handleDeleteTribute={this.handleDeleteTribute}
                              handleEditTribute={this.handleEditTribute}
                              message={message}
                              tributeIndex={index}
                              type="memorial"
                            />
                          )}
                          {type === 'QuoteAndMessage' && (
                            <QuoteAndMessage
                              allowEdit
                              eventDate={eventDate}
                              eventDay={eventDay}
                              eventMonth={eventMonth}
                              eventYear={eventYear}
                              handleDeleteTribute={this.handleDeleteTribute}
                              handleEditTribute={this.handleEditTribute}
                              message={message}
                              quote={quote}
                              quoteBackgroundColor={quoteBackgroundColor}
                              quoteBackgroundImage={quoteBackgroundImage}
                              quoteFontColor={quoteFontColor}
                              tributeIndex={index}
                              type="memorial"
                            />
                          )}
                          {type === 'YoutubeVideo' && (
                            <YoutubeVideo
                              allowEdit
                              eventDate={eventDate}
                              eventDay={eventDay}
                              eventMonth={eventMonth}
                              eventYear={eventYear}
                              handleDeleteTribute={this.handleDeleteTribute}
                              handleEditTribute={this.handleEditTribute}
                              tributeIndex={index}
                              videoUrl={videoUrl}
                            />
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
            {activeTab === 'public-tributes' && (
              <div className="mt3">
                <section>
                  <h3>Adicionar Homenagem Pública</h3>
                  <div className="mb3 w-100 input">
                    <input
                      name="socialEntryUrl"
                      value={socialEntryUrl}
                      onChange={event => this.setState({ [event.target.name]: event.target.value })}
                      type="text"
                      placeholder="URL"
                    />
                  </div>
                </section>
                <button
                  type="button"
                  className="btn btn-primary btn-primary-outline"
                  onClick={this.addSocialEntry}
                >
                  Adicionar Social Entry
                </button>
              </div>
            )}
            {activeTab === 'guestbook' && (
              <div>
                {shouldShowUnapprovedGuestbookEntries && (
                  <div>
                    <h2 className="fw4">Mensagens Pendentes</h2>
                    <div>
                      <ul className="list flex">
                        {guests
                          .filter(guestbook => !guestbook.approved)
                          .map((guestbookEntry, index) => {
                            const { id, message, user } = guestbookEntry
                            const { name, socialId } = user
                            return (
                              // eslint-disable-next-line react/no-array-index-key
                              <li key={index} className="relative w-100 pa2 pt4 ma0 mb2">
                                <GuestbookEntry
                                  handleApproveGuestbookEntry={this.handleApproveGuestbookEntry}
                                  handleDeleteGuestbookEntry={this.handleDeleteGuestbookEntry}
                                  name={name}
                                  photo={`https://graph.facebook.com/${socialId}/picture?type=normal&width=200&height=200`}
                                  dateCreated={guestbookEntry.dateCreated}
                                  message={message}
                                  guestbookEntryId={id}
                                  allowEdit
                                />
                              </li>
                            )
                          })}
                      </ul>
                    </div>
                  </div>
                )}
                {shouldShowApprovedGuestbookEntries && (
                  <div>
                    <h2 className="fw4">Mensagens Publicadas</h2>
                    <div className="masonry">
                      {guests
                        .filter(guestbook => guestbook.approved)
                        .map((guestbookEntry, index) => {
                          const { message, user } = guestbookEntry
                          const { name, socialId } = user
                          return (
                            <div
                              // eslint-disable-next-line react/no-array-index-key
                              key={index}
                              className="masonry-item relative w-100 pa2 pt4 ma0 mb2"
                            >
                              <GuestbookEntry
                                name={name}
                                photo={`https://graph.facebook.com/${socialId}/picture?type=normal&width=200&height=200`}
                                dateCreated={guestbookEntry.dateCreated}
                                message={message}
                              />
                            </div>
                          )
                        })}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="pl2-ns w-100 w-auto-ns mv3 flex flex-wrap justify-between">
          <div className="w-100 w-third-l pr2-ns">
            <button
              type="button"
              className="btn btn-primary lh-title w-100 w-auto-ns"
              onClick={() => {
                updateStore({
                  image: null,
                })
                jumpToStep(0)
              }}
            >
              Adicionar mais Conteúdo
            </button>
          </div>
          <div className="w-100 w-third-l ph2-ns pt1-ns mt3 mb4 mv0-l">
            <Promos handleApplyPromo={this.handleApplyPromo} price={price} />
          </div>
          <div className="w-100 w-third-l pl2-ns">
            {available && (
              <button
                type="button"
                className="btn btn-primary lh-title w-100 w-auto-ns"
                onClick={() => {
                  this.saveHeader()
                  this.persistData()
                }}
              >
                Atualizar Memorial
              </button>
            )}
            {!available && (
              <div>
                <PaypalButton price={price} onClick={() => this.persistData()} />
                <small className="db mv2 gray">
                  ...e seu memorial será publicado em instantes.
                  <br />
                  <small className="db mv3 gray b">
                    Cartões aceitos: Visa, Mastercard ou American Express
                  </small>
                </small>
              </div>
            )}
          </div>
        </div>
      </section>
    )
  }
}

export default injectIntl(withFirebase(Step4))
