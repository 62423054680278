import React, { Component } from 'react'
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete'

import FormInlineError from '../helpers/FormInlineError'
import { getBrazilianStateNameFromUF } from '../helpers/UIHelpers'

class City extends Component {
  constructor(props) {
    super(props)
    this.state = {
      address: '',
      isValid: false,
    }
  }

  handleChange = address => {
    this.setState({
      address,
      isValid: false,
    })
  }

  handleSelect = (address, placeId) => {
    const { onSelectCity } = this.props
    geocodeByPlaceId(placeId).then(results => {
      const components = results[0].address_components
      const city = components.filter(component => component.types.includes('locality'))[0].long_name
      const state = components.filter(component =>
        component.types.includes('administrative_area_level_1')
      )[0].long_name
      if (placeId) {
        this.setState({
          address: `${city}, ${state}`,
          isValid: true,
        })
        onSelectCity(address)
      }
    })
  }

  render() {
    const { address, isValid } = this.state
    const { errorMessage, shouldShowCityError } = this.props
    return (
      <PlacesAutocomplete
        value={address}
        onChange={e => this.handleChange(e)}
        onSelect={this.handleSelect}
        searchOptions={{
          language: 'pt-BR',
          country: 'br',
          components: 'country:br',
          types: ['(cities)'],
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className={`input ${shouldShowCityError && !isValid ? 'has-error' : ''}`}>
            <input
              type="text"
              value={address}
              {...getInputProps({
                className: `ttc location-search-input ${address !== '' ? 'filled' : ''}`,
              })}
            />
            <label htmlFor="city">Cidade</label>
            {shouldShowCityError && !isValid && <FormInlineError error={errorMessage} />}
            {!loading && suggestions.length > 0 && (
              <div className="ba top-2 mt2 absolute right-0 left-0 ba br2 b--mid-gray bw1 bg-white z-max tl">
                {loading && <div>Loading...</div>}
                {suggestions.map(suggestion => {
                  const className = `pa3 hover-gray pointer ${
                    suggestion.active ? 'bg-washed-blue black' : 'suggestion-item'
                  }`
                  const isBrazil =
                    suggestion.terms &&
                    suggestion.terms[suggestion.terms.length - 1].value === 'Brasil'
                  const hasCityAndState =
                    suggestion.terms[suggestion.terms.length - 2] &&
                    suggestion.terms[suggestion.terms.length - 3]
                  if (isBrazil && hasCityAndState) {
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                        })}
                      >
                        <span>
                          {suggestion.terms[suggestion.terms.length - 3].value}
                          {', '}
                          {getBrazilianStateNameFromUF(
                            suggestion.terms[suggestion.terms.length - 2].value
                          )}
                        </span>
                      </div>
                    )
                  }
                  return null
                })}
              </div>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    )
  }
}

export default City
