import React from 'react'

const QuoteAndMessage = props => {
  let { message, quote, quoteBackgroundImage, quoteFontColor, sender } = props
  const { allowEdit, quoteBackgroundColor, type } = props
  message = (
    message ||
    (type === 'memorial'
      ? 'Digite a mensagem que deseja adicionar ao memorial.'
      : `Informamos com pesar o falecimento do nosso (grande amigo ou tio/pai/filho etc. querido)
        (nome). Sua morte nos deixou muito surpresos, mas esperamos que ele/ela possa estar em um lugar melhor.`)
  ).trim()
  sender = sender || (type === 'memorial' ? null : 'Nome da Família')
  const shouldShowTextContent = sender || message
  quoteBackgroundImage = !quoteBackgroundColor ? `url(${quoteBackgroundImage})` : null
  quoteFontColor = quoteFontColor || 'white'
  quote = quote || 'A amizade sincera é termo gravado na lápide do tempo'

  const handleDeleteTributeAction = () => {
    const { handleDeleteTribute, tributeIndex } = props
    handleDeleteTribute(tributeIndex)
  }

  const handleEditTributeAction = () => {
    const { handleEditTribute, tributeIndex } = props
    handleEditTribute('QuoteAndMessage', { tributeIndex, ...props })
  }

  return (
    <section className="b--mid-gray bg-light-gray br3 overflow-hidden flex-auto flex-column flex relative">
      <div
        className="f3 white h5 flex items-center ph4 lh-title cover justify-center tc"
        style={{
          backgroundColor: quoteBackgroundColor,
          backgroundImage: quoteBackgroundImage,
          color: quoteFontColor,
          backgroundRepeat: 'no-repeat',
        }}
      >
        {quote}
      </div>
      {shouldShowTextContent && (
        <div className="tc ma3 lh-copy f6 f5-ns">
          {message && <p className="ma0">{message}</p>}
          {sender && <p className="b i ma0 mt2">{sender}</p>}
        </div>
      )}
      {allowEdit && (
        <div className="absolute bottom-0 right-0 ma2 flex justify-end bg-white-90 br2">
          <div
            role="button"
            tabIndex={0}
            className="material-icons pointer pa1"
            title="Excluir"
            onClick={handleDeleteTributeAction}
            onKeyDown={handleDeleteTributeAction}
          >
            delete
          </div>
          <div
            role="button"
            tabIndex={0}
            className="material-icons pointer pa1"
            title="Alterar"
            onClick={handleEditTributeAction}
            onKeyDown={handleEditTributeAction}
          >
            edit
          </div>
        </div>
      )}
    </section>
  )
}

export default QuoteAndMessage
