import React, { Component } from 'react'
import ptBR from 'date-fns/locale/pt-BR'
import { formatDistance } from 'date-fns'
import { withFirebase } from '../../firebase/context'

class GuestbookEntry extends Component {
  componentWillReceiveProps(nextProps) {
    this.setState({
      ...nextProps,
    })
  }

  handleGuestbookEntryApprovalAction = () => {
    const { handleApproveGuestbookEntry, guestbookEntryId } = this.props
    handleApproveGuestbookEntry(guestbookEntryId)
  }

  handleGuestbookEntryDeletionAction = () => {
    const { handleDeleteGuestbookEntry, guestbookEntryId } = this.props
    handleDeleteGuestbookEntry(guestbookEntryId)
  }

  render = () => {
    const { allowEdit, name, message, photo, dateCreated } = this.props

    return (
      <div className="bg-light-gray br3 tc relative cf">
        <img alt={name} className="br-pill w4 h4 nt4" src={photo} />
        <h3 className="f3 fw4 mt2 mb2">{name}</h3>
        <p className="mid-gray f7 i mt0">
          Publicado há&nbsp;
          {formatDistance(new Date(dateCreated), new Date(), {
            locale: ptBR,
          })}
        </p>
        <p className="lh-copy mb3 mb4-ns ph3 ph4-ns">{message}</p>
        {allowEdit && (
          <div className="absolute bottom-0 right-0 ma2 flex justify-end bg-white-90 br2">
            <div
              role="button"
              tabIndex={0}
              className="material-icons green pointer pa1"
              title="Alterar"
              onClick={this.handleGuestbookEntryApprovalAction}
              onKeyDown={this.handleGuestbookEntryApprovalAction}
            >
              thumb_up
            </div>
            <div
              role="button"
              tabIndex={0}
              className="material-icons pointer pa1"
              title="Excluir"
              onClick={this.handleGuestbookEntryDeletionAction}
              onKeyDown={this.handleGuestbookEntryDeletionAction}
            >
              thumb_down
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default withFirebase(GuestbookEntry)
