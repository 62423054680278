import React, { Component } from 'react'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import { confirmAlert } from 'react-confirm-alert'

import City from '../City/City'
import FormInlineError from '../helpers/FormInlineError'
import { AuthUserContext } from '../Session'
import { toTitleCase, isEmail } from '../helpers/UIHelpers'
import { withFirebase } from '../firebase/context'

class Step1 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasSubmittedOnce: false,
      personBirthday: props.getStore().personBirthday,
      personCity: props.getStore().personCity,
      personDeathday: props.getStore().personDeathday,
      personName: props.getStore().personName,
      userEmail: props.getStore().userEmail,
      userName: props.getStore().userName,
    }
    const { updateStore } = this.props
    this.handlePersonBirthdayChange = this.handlePersonBirthdayChange.bind(this)
    this.handlePersonDeathdayChange = this.handlePersonDeathdayChange.bind(this)
    this.validateOnDemand = true
    this.validationCheck = this.validationCheck.bind(this)
    this.isValidated = this.isValidated.bind(this)
    updateStore({
      defaultQuoteBackgroundImage:
        'https://firebasestorage.googleapis.com/v0/b/immortalis.appspot.com/o/site%2Ftexto-imagem.jpg?alt=media&token=063ba1a8-fb35-48f0-9740-02bea88a9f67',
    })
  }

  componentDidMount() {
    registerLocale('ptBR', ptBR)
    setDefaultLocale('ptBR')
  }

  handleCity = cityValue => {
    const { updateStore } = this.props
    this.validationCheck()
    updateStore({
      personCity: cityValue,
    })
    this.setState({
      personCity: cityValue,
    })
  }

  handlePersonBirthdayChange = personBirthdayValue => {
    const { updateStore } = this.props
    this.setState({
      personBirthday: personBirthdayValue,
    })
    updateStore({
      personBirthday: personBirthdayValue,
    })
  }

  handlePersonDeathdayChange = personDeathdayValue => {
    const { updateStore } = this.props
    updateStore({
      personDeathday: personDeathdayValue,
    })
    this.setState({
      personDeathday: personDeathdayValue,
    })
  }

  goToNextStep = e => {
    e.preventDefault()
    this.setState({
      hasSubmittedOnce: true,
    })
    this.isValidated().then(isValid => {
      if (isValid) {
        this.persistData()
      }
    })
  }

  persistData = e => {
    const { firebase, getStore, jumpToStep, updateStore } = this.props
    const { firestore } = firebase
    const firestoreSettings = {
      timestampsInSnapshots: true,
    }
    firestore.settings(firestoreSettings)
    const data = getStore()
    const personData = {
      available: false,
      birthday: data.personBirthday,
      city: data.personCity,
      deathday: data.personDeathday,
      memorial: null,
      obituaries: [],
      personName: data.personName,
    }

    const addPerson = person =>
      new Promise((resolve, reject) => {
        firestore
          .collection('People')
          .add(person)
          .then(addedPerson => {
            // console.log(`Added person with ID: ${addedPerson.id}`)
            updateStore({
              person: addedPerson,
            })
            resolve(addedPerson)
          })
      })

    addPerson(personData).then(personKey => {
      updateStore({
        personId: personKey.id,
      })
      jumpToStep(1)
    })
  }

  validationErrors = val => {
    const errMsgs = {
      personNameValMsg: val.personNameVal
        ? ''
        : 'Digite os primeiro e último nomes da pessoa homenageada.',
      personCityValMsg:
        val.personCityVal === '' ? '' : 'Digite a cidade e selecione na lista a opção desejada.',
      personBirthdayValMsg: val.personBirthdayVal
        ? ''
        : 'Informe da data de nascimento da pessoa homenageada.',
      personDeathdayValMsg:
        val.personDeathdayVal === '' ? '' : 'Informe da data de falecimento da pessoa homenageada.',
      userNameValMsg: val.userName
        ? ''
        : 'Digite o nome que será apresentado como publicador da nota.',
      userEmailValMsg: val.userEmail ? '' : 'Insira um email válido',
    }
    return errMsgs
  }

  validateData = data => ({
    personBirthdayVal: !!data.personBirthday,
    personCityVal: data.personCity !== '' && data.personCity !== undefined,
    personDeathdayVal: !!data.personDeathday,
    personNameVal: data.personName !== '' && data.personName.indexOf(' ') !== -1,
    userEmailVal: !data.userEmail || isEmail(data.userEmail),
    userNameVal: !data.userName || data.userName !== '',
  })

  isExistingUser = () =>
    new Promise(resolve => {
      const { firebase, getStore } = this.props
      const { auth } = firebase
      const isLoggedIn = !!auth.currentUser
      if (isLoggedIn) {
        resolve(false)
      } else {
        auth.fetchSignInMethodsForEmail(getStore().userEmail).then(activeSignInMethods => {
          if (activeSignInMethods.length) {
            confirmAlert({
              customUI: ({ onClose }) => (
                <div className="fixed top-0 bg-black-30 left-0 w-100 h-100 flex items-center justify-center sans-serif">
                  <div className="box pa3 w-90 w-50-m w-40-l tc">
                    <p className="mb4 lh-title">
                      Um usuário com este e-mail já está cadastrado no Immortalis.
                    </p>
                    <div className="flex w-100 flex-wrap justify-center">
                      <button
                        type="button"
                        className="mh2 w-100 w-auto-ns mr2-ns btn btn-primary mb2 mb0-ns"
                        onClick={() => {
                          window.location.href = '/signin'
                        }}
                      >
                        Acessar Conta
                      </button>
                    </div>
                  </div>
                </div>
              ),
            })
          } else {
            resolve(false)
          }
        })
      }
    })

  validationCheck() {
    if (!this.validateOnDemand) return
    const userInput = this.grabUserInput() // grab user entered vals
    const validateNewInput = this.validateData(userInput) // run the new input against the validator
    this.setState(
      Object.assign(userInput, validateNewInput, this.validationErrors(validateNewInput))
    )
  }

  isValidated() {
    const userInput = this.grabUserInput()
    const validateNewInput = this.validateData(userInput)
    const { getStore, updateStore } = this.props
    return new Promise((resolve, reject) => {
      if (Object.keys(validateNewInput).every(k => validateNewInput[k] === true)) {
        if (
          getStore().personBirthday !== userInput.personBirthday ||
          getStore().personDeathday !== userInput.personDeathday ||
          getStore().personCity !== userInput.personCity ||
          getStore().personName !== userInput.personName ||
          getStore().userEmail !== userInput.userEmail ||
          getStore().userName !== userInput.userName
        ) {
          updateStore({
            ...userInput,
            savedToCloud: false,
          })
        }
        this.isExistingUser().then(isExisting => {
          resolve(!isExisting)
        })
      } else {
        this.setState(
          Object.assign(userInput, validateNewInput, this.validationErrors(validateNewInput))
        )
        resolve(false)
      }
    })
  }

  grabUserInput() {
    const { getStore } = this.props
    const { personCity, personBirthday, personDeathday } = this.state
    return {
      personBirthday,
      personCity,
      personDeathday,
      personName: this.personName.value,
      userEmail: this.userEmail ? this.userEmail.value : getStore().userEmail,
      userName: this.userName ? this.userName.value : getStore().userName,
    }
  }

  render() {
    const {
      personBirthday,
      personBirthdayVal,
      personBirthdayValMsg,
      personCityVal,
      personCityValMsg,
      personDeathday,
      personDeathdayVal,
      personDeathdayValMsg,
      personNameVal,
      personNameValMsg,
      personName,
      userEmail,
      userEmailVal,
      userEmailValMsg,
      userName,
      userNameVal,
      userNameValMsg,
      hasSubmittedOnce,
    } = this.state
    const { updateStore } = this.props
    const shouldShowPersonBirthdayError = hasSubmittedOnce && !personBirthdayVal
    const shouldShowPersonCityError = hasSubmittedOnce && personCityVal === false
    const shouldShowPersonDeathdayError = hasSubmittedOnce && !personDeathdayVal
    const shouldShowPersonNameError = hasSubmittedOnce && personNameVal === false
    const shouldShowUserEmailError = hasSubmittedOnce && userEmailVal === false
    const shouldShowUserNameError = hasSubmittedOnce && userNameVal === false
    return (
      <section className="box tc ph2 w-100 pa3 pa4-ns mb3">
        <div className="mw8-ns center mt3-ns">
          <div className="">
            <form>
              <div className="flex flex-wrap w-50-ns center">
                <h2 className="ma0 mb3 mb4-ns fw5 f4 tc w-100 lh-title ph3 f5 f4-ns">
                  Informe os dados da pessoa homenageada
                </h2>
                <div className="mb3 w-100">
                  <div className={`input ${shouldShowPersonNameError ? 'has-error' : ''}`}>
                    <input
                      ref={c => {
                        this.personName = c
                      }}
                      id="personName"
                      autoComplete="off"
                      value={personName}
                      onChange={e => {
                        this.setState({
                          personName: toTitleCase(e.target.value),
                        })
                      }}
                      type="email"
                      className={`${personName !== '' ? 'filled' : ''}`}
                      onBlur={this.validationCheck}
                    />
                    <label htmlFor="personName">Nome da Pessoa Homenageada</label>
                    {shouldShowPersonNameError && <FormInlineError error={personNameValMsg} />}
                  </div>
                </div>
                <div className="mb3 w-100 w-50-l pr0 relative">
                  <DatePicker
                    selected={personBirthday}
                    onChange={this.handlePersonBirthdayChange}
                    dateFormat={`d 'de' MMMM 'de' yyyy`}
                    timeCaption="Hora"
                    locale="ptBR"
                    placeholderText="Data de Nascimento"
                    showYearDropdown
                    openToDate={new Date('1940/01/01')}
                    className={`b--solid bw1 br2 ${
                      shouldShowPersonBirthdayError ? 'b--red' : 'b--mid-gray'
                    }`}
                  />
                  {shouldShowPersonBirthdayError && (
                    <FormInlineError error={personBirthdayValMsg} />
                  )}
                </div>
                <div className="mb3 w-100 w-50-l pl0 pl2-l relative">
                  <DatePicker
                    selected={personDeathday}
                    onChange={this.handlePersonDeathdayChange}
                    dateFormat={`d 'de' MMMM 'de' yyyy`}
                    locale="ptBR"
                    placeholderText="Data de Falecimento"
                    todayButton="Hoje"
                    className={`b--solid bw1 br2 ${
                      shouldShowPersonDeathdayError ? 'b--red' : 'b--mid-gray'
                    }`}
                  />
                  {shouldShowPersonDeathdayError && (
                    <FormInlineError error={personDeathdayValMsg} />
                  )}
                </div>
                <div className="mb3 w-100">
                  <City
                    onSelectCity={this.handleCity}
                    shouldShowCityError={shouldShowPersonCityError}
                    errorMessage={personCityValMsg}
                  />
                </div>
                <AuthUserContext.Consumer>
                  {authUser => {
                    if (authUser) {
                      updateStore({
                        userEmail: authUser.email,
                        userName: authUser.displayName,
                      })
                    }
                    return (
                      !authUser && (
                        <div>
                          <h2 className="ma0 mb0 fw5 f4 tc w-100 lh-title ph3 f5 f4-ns">
                            Seus Dados
                          </h2>
                          <p className="mt2 mb3 gray f6 f5-ns tc w-100 lh-title">
                            Digite seu nome e email para enviarmos informações sobre o tributo
                            criado
                          </p>
                          <div className="mb3 w-100">
                            <div className={`input ${shouldShowUserNameError ? 'has-error' : ''}`}>
                              <input
                                ref={c => {
                                  this.userName = c
                                }}
                                id="userName"
                                defaultValue={userName}
                                type="text"
                                className={`${userName !== '' ? 'filled' : ''}`}
                                onBlur={this.validationCheck}
                              />
                              <label htmlFor="userName">Seu Nome</label>
                              {shouldShowUserNameError && (
                                <FormInlineError error={userNameValMsg} />
                              )}
                            </div>
                          </div>
                          <div className="mb3 w-100">
                            <div className={`input ${shouldShowUserEmailError ? 'has-error' : ''}`}>
                              <input
                                ref={c => {
                                  this.userEmail = c
                                }}
                                id="userEmail"
                                autoComplete="off"
                                value={userEmail}
                                type="email"
                                className={`ttl ${userEmail !== '' ? 'filled' : ''}`}
                                onChange={event => {
                                  this.setState({ [event.target.id]: event.target.value })
                                  updateStore({ [event.target.id]: event.target.value })
                                }}
                                onKeyDown={e => {
                                  if (e.keyCode === 13) {
                                    this.goToNextStep(e)
                                  }
                                }}
                                onBlur={this.validationCheck}
                              />
                              <label htmlFor="userEmail">Seu Email</label>
                              {shouldShowUserEmailError && (
                                <FormInlineError error={userEmailValMsg} />
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    )
                  }}
                </AuthUserContext.Consumer>
                <div className="w-100 tr">
                  <button
                    type="button"
                    onClick={e => this.goToNextStep(e)}
                    className="btn btn-primary"
                  >
                    Próximo Passo
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    )
  }
}

export default withFirebase(Step1)
