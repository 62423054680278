import React from 'react'

const ImageAndMessage = props => {
  let { image, message, sender } = props
  const { allowEdit, alt, personName, type } = props
  const isPreview = !message
  image =
    image ||
    'https://firebasestorage.googleapis.com/v0/b/immortalis.appspot.com/o/site%2Ftexto-imagem.jpg?alt=media&token=063ba1a8-fb35-48f0-9740-02bea88a9f67'
  message =
    message ||
    (type === 'memorial'
      ? 'Digite a mensagem que deseja publicar com a imagem.'
      : `Informamos com pesar o falecimento do nosso (grande amigo ou tio/pai/filho etc. querido)
        (nome). Sua morte nos deixou muito surpresos, mas esperamos que ele/ela possa estar em um lugar melhor.`)
  sender = sender || (type === 'memorial' ? null : 'Nome da Família')

  const handleDeleteTributeAction = () => {
    const { handleDeleteTribute, tributeIndex } = props
    handleDeleteTribute(tributeIndex)
  }

  const handleEditTributeAction = () => {
    const { handleEditTribute, tributeIndex } = props
    handleEditTribute('ImageAndMessage', { tributeIndex, ...props })
  }

  return (
    <section
      className={`${
        type === 'memorial' || (type === 'memorial' && !isPreview) ? 'bg-light-gray' : 'bg-white'
      } br3 overflow-hidden flex-auto flex-column flex justify-center w-100 h-auto relative`}
    >
      <img className="db w-100" alt={alt} src={image} />
      <div className="ma3 f6 f5-ns">
        {!!personName && <div className="w-100 tc fw5 f4">{personName}</div>}
        <p
          className="tc lh-copy"
          style={{
            whiteSpace: 'pre-line',
          }}
        >
          {message}
        </p>
        {sender && <p className="b i mb3">{sender}</p>}
      </div>
      {allowEdit && (
        <div className="absolute bottom-0 right-0 ma2 flex justify-end bg-white-90 br2">
          <div
            role="button"
            tabIndex={0}
            className="material-icons pointer pa1"
            title="Excluir"
            onClick={handleDeleteTributeAction}
            onKeyDown={handleDeleteTributeAction}
          >
            delete
          </div>
          <div
            role="button"
            tabIndex={0}
            className="material-icons pointer pa1"
            title="Alterar"
            onClick={handleEditTributeAction}
            onKeyDown={handleEditTributeAction}
          >
            edit
          </div>
        </div>
      )}
    </section>
  )
}

export default ImageAndMessage
