import React from 'react'
import { getStringParams, isYouTubeUrl } from '../helpers/UIHelpers'
import YoutubeVideoImage from '../../images/YoutubeVideo.png'

const YoutubeVideo = props => {
  const { allowEdit, videoUrl } = props
  let embedUrl
  const isValidYoutubeVideo = isYouTubeUrl(videoUrl)
  if (isValidYoutubeVideo) {
    embedUrl = `https://www.youtube.com/embed/${getStringParams(videoUrl).v}`
  } else {
    return (
      <div
        className="outline-0 aspect-ratio aspect-ratio--16x9 ba cover bg-center"
        style={{
          backgroundImage: `url(${YoutubeVideoImage})`,
        }}
      >
        <div alt="Vídeo" className="aspect-ratio--object h-auto" />
      </div>
    )
  }

  const handleDeleteTributeAction = () => {
    const { handleDeleteTribute, tributeIndex } = props
    handleDeleteTribute(tributeIndex)
  }

  const handleEditTributeAction = () => {
    const { handleEditTribute, tributeIndex } = props
    handleEditTribute('YoutubeVideo', { tributeIndex, ...props })
  }

  return (
    isValidYoutubeVideo && (
      <section
        className="bg-light-gray br3 overflow-hidden flex-auto flex-column flex relative"
        style={{ transform: 'translateY(0)' }}
      >
        <div
          className="w-100 relative"
          style={{
            height: 0,
            paddingBottom: '56.25%',
          }}
        >
          <iframe
            title="YouTube Video"
            src={embedUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="absolute top-0 left-0 w-100 h-100"
          />
        </div>
        {allowEdit && (
          <div className="absolute bottom-0 right-0 ma2 flex justify-end bg-white-90 br2">
            <div
              role="button"
              tabIndex={0}
              className="material-icons pointer pa1"
              title="Excluir"
              onClick={handleDeleteTributeAction}
              onKeyDown={handleDeleteTributeAction}
            >
              delete
            </div>
            <div
              role="button"
              tabIndex={0}
              className="material-icons pointer pa1"
              title="Alterar"
              onClick={handleEditTributeAction}
              onKeyDown={handleEditTributeAction}
            >
              edit
            </div>
          </div>
        )}
      </section>
    )
  )
}

export default YoutubeVideo
