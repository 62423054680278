/* eslint-disable react/sort-comp */
import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import { differenceInDays } from 'date-fns'
import { confirmAlert } from 'react-confirm-alert'

import { withFirebase } from '../firebase/context'
import { daysInMonth, isYouTubeUrl, randomString, range } from '../helpers/UIHelpers'
import FormInlineError from '../helpers/FormInlineError'
import { quotes, quotesBackgrounds } from '../Quotes'

import {
  FuneralInfo,
  ImageAndMessage,
  ImageOnly,
  MessageOnly,
  QuoteAndMessage,
  YoutubeVideo,
} from '../Templates'
import FuneralInfoImage from '../../images/FuneralInfo.png'
import ImageAndMessageImage from '../../images/ImageAndMessage.png'
import MessageOnlyImage from '../../images/MessageOnly.png'
import PhotoImage from '../../images/Photo.png'
import QuoteAndMessageImage from '../../images/QuoteAndMessage.png'
import YoutubeVideoImage from '../../images/YoutubeVideo.png'

const SibApiV3Sdk = require('sib-api-v3-sdk')

const defaultClient = SibApiV3Sdk.ApiClient.instance

class Step3 extends Component {
  constructor(props) {
    super(props)
    const {
      action,
      eventDay,
      eventMonth,
      eventYear,
      image,
      message,
      personBirthday,
      personDeathday,
      postType,
      quote,
      quoteBackgroundColor,
      quoteBackgroundImage,
      quoteFontColor,
      venueAddress,
      venueDateTime,
      venueName,
      videoUrl,
    } = props.getStore()
    this.state = {
      daysInMonthOptions: 31,
      action: action || 'selectPostType',
      eventDay: eventDay || 0,
      eventMonth: eventMonth || 0,
      eventYear: eventYear || 0,
      selectedQuoteIndex: quotes.indexOf(quote),
      hasSubmittedOnce: false,
      message: message || '',
      postType: postType || '',
      image: image || null,
      quoteBackgroundColor: quoteBackgroundColor || 0,
      quoteBackgroundImage:
        quoteBackgroundImage ||
        'https://firebasestorage.googleapis.com/v0/b/immortalis.appspot.com/o/site%2Ftexto-imagem.jpg?alt=media&token=063ba1a8-fb35-48f0-9740-02bea88a9f67',
      quoteFontColor,
      personBirthday,
      personDeathday,
      quote,
      venueAddress,
      venueDateTime,
      venueName,
      videoUrl: videoUrl || '',
    }
    const { updateStore } = this.props

    this.validateOnDemand = true
    this.validationCheck = this.validationCheck.bind(this)
    this.isValidated = this.isValidated.bind(this)
    updateStore({
      defaultQuoteBackgroundImage:
        'https://firebasestorage.googleapis.com/v0/b/immortalis.appspot.com/o/site%2Ftexto-imagem.jpg?alt=media&token=063ba1a8-fb35-48f0-9740-02bea88a9f67',
    })
  }

  componentDidMount() {
    registerLocale('ptBR', ptBR)
    setDefaultLocale('ptBR')
  }

  onMessageChange(message) {
    const { updateStore } = this.props
    this.setState({
      message,
    })
    updateStore({
      message,
    })
    this.validationCheck()
  }

  onInputChange(text, input) {
    const { updateStore } = this.props
    this.setState({
      [input]: text,
    })
    updateStore({
      [input]: text,
    })
    this.validationCheck()
  }

  grabUserInput() {
    const { image, quote, venueDateTime } = this.state
    return {
      image,
      message: this.message ? this.message.value : null,
      quote,
      venueAddress: this.venueAddress ? this.venueAddress.value : null,
      venueDateTime,
      venueName: this.venueName ? this.venueName.value : null,
      videoUrl: this.videoUrl ? this.videoUrl.value : null,
    }
  }

  validateData = (data, postType) => {
    const shouldValidateMessage = [
      'FuneralInfo',
      'ImageAndMessage',
      'MessageOnly',
      'QuoteAndMessage',
    ].includes(postType)
    const shouldValidatePhoto = ['ImageOnly', 'ImageAndMessage'].includes(postType)
    const shouldValidateQuote = ['QuoteAndMessage'].includes(postType)
    const shouldValidateVenue = ['FuneralInfo'].includes(postType)
    const shouldValidateVideoUrl = ['YoutubeVideo'].includes(postType)
    return {
      messageValid: shouldValidateMessage ? data.message !== '' : true,
      imageValid: shouldValidatePhoto ? !!data.image : true,
      quoteValid: shouldValidateQuote ? !!data.quote : true,
      venueAddressValid: shouldValidateVenue ? !!data.venueAddress : true,
      venueDateTimeValid: shouldValidateVenue ? !!data.venueDateTime : true,
      venueNameValid: shouldValidateVenue ? !!data.venueName : true,
      videoUrlValid: shouldValidateVideoUrl ? isYouTubeUrl(data.videoUrl) : true,
    }
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    const reader = new FileReader()
    const $this = this
    const { personId } = $this.props.getStore()
    const { firebase, updateStore } = $this.props
    const { storage } = firebase
    const uploadImage = image =>
      new Promise(resolve => {
        if (!image) {
          resolve()
        }
        const storageRef = storage.ref()
        const imagePath = `site/memorials/${personId}/${randomString(20)}`
        const imageRef = storageRef.child(imagePath)
        imageRef.put(image).then(snapshot => {
          storageRef
            .child(imagePath)
            .getDownloadURL()
            .then(url => {
              resolve(url)
            })
        })
      })

    reader.onload = e => {
      uploadImage(acceptedFiles[0]).then(url => {
        updateStore({
          image: url,
        })
        $this.setState({
          image: url,
        })
        $this.validationCheck()
      })
    }
    reader.readAsDataURL(acceptedFiles[0])
  }

  validationErrors = val => {
    const {
      imageValid,
      messageValid,
      venueAddressValid,
      venueDateTimeValid,
      venueNameValid,
      videoUrlValid,
    } = val
    const errMsgs = {
      imageErrorMsg: imageValid ? '' : 'Selecione a imagem que deseja adicionar ao memorial.',
      messageErrorMsg: messageValid ? '' : 'Digite a mensagem que deseja adicionar ao memorial.',
      venueAddressErrorMsg: venueAddressValid ? '' : 'Digite o endereço do local.',
      venueDateTimeErrorMsg: venueDateTimeValid ? '' : 'Selecione o dia e horário do evento.',
      venueNameErrorMsg: venueNameValid
        ? ''
        : 'Digite o nome do local onde será realizado o evento.',
      videoUrlErrorMsg: videoUrlValid
        ? ''
        : 'Informe um link de vídeo no YouTube no formato https://www.youtube.com/watch?v=Kt1Izjm6G6Y',
    }
    return errMsgs
  }

  addToMemorial = e => {
    const { firebase, getStore } = this.props
    const { firestore } = firebase
    const { eventYear, eventMonth, eventDay, personBirthday, postType } = this.state
    const firestoreSettings = {
      timestampsInSnapshots: true,
    }
    let eventDate = new Date(eventYear, eventMonth === 0 ? 1 : eventMonth - 1, eventDay)
    if (eventDate.getFullYear() < personBirthday.getFullYear()) {
      eventDate = null
    } else {
      eventDate = {
        nanoseconds: 0,
        seconds: eventDate.getTime() / 1000,
      }
    }
    firestore.settings(firestoreSettings)
    e.preventDefault()
    this.setState({
      hasSubmittedOnce: true,
    })
    if (this.isValidated()) {
      const data = getStore()
      const memorialItemData = {
        dateCreated: {
          nanoseconds: 0,
          seconds: new Date().getTime() / 1000,
        },
        eventDate,
        eventDay,
        eventMonth,
        eventYear,
        image: data.image || '',
        message: data.message || '',
        quote: data.quote || '',
        quoteBackgroundColor: data.quoteBackgroundColor || '',
        quoteBackgroundImage: data.quoteBackgroundImage || '',
        quoteFontColor: data.quoteFontColor || '',
        type: postType,
        venueAddress: data.venueAddress || '',
        venueDateTime: data.venueDateTime || null,
        venueName: data.venueName || '',
        videoUrl: data.videoUrl || '',
      }

      const addItemToMemorial = itemData =>
        new Promise((resolve, reject) => {
          const docRef = firestore.collection('Memorials').doc(data.memorialId)
          firestore.runTransaction(t =>
            t
              .get(docRef)
              .then(doc => {
                const obj = doc.get('tributes') ? doc.get('tributes') : []
                obj.push(itemData)
                t.set(docRef, { tributes: obj }, { merge: true })
              })
              .then(result => {
                resolve(result)
              })
          )
        })

      addItemToMemorial(memorialItemData).then(memorialTributeKey => {
        // console.log(`Item added: ${memorialTributeKey} `)
        this.setState({
          action: 'contentAdded',
        })
      })
    }
  }

  visualizeAndPublish = () => {
    const { firebase, getStore } = this.props
    const { auth } = firebase
    const { memorialId, userEmail, userName } = getStore()

    const sendMail = (person, password) =>
      new Promise(resolve => {
        const apiKey = defaultClient.authentications['api-key']
        apiKey.apiKey = process.env.GATSBY_SENDINBLUE_APIKEY
        const smtpApi = new SibApiV3Sdk.SMTPApi()
        const sendSmtpEmail = new SibApiV3Sdk.SendSmtpEmail()
        sendSmtpEmail.sender = {
          name: 'Immortalis',
          email: 'noreply@immortalis.com.br',
        }
        const personFirstName =
          person.name.indexOf(' ') > 0 ? person.name.split(' ')[0] : person.name
        sendSmtpEmail.subject = 'Seja bem-vindo ao Immortalis'
        sendSmtpEmail.htmlContent = `
          <p>Olá, ${personFirstName}. Seja bem-vindo ao Immortalis.</p>
          <p>Veja abaixo os seus dados de acesso:</p>
          <p>
            <b>Usuário: ${person.email}<b>
            <b>Senha: ${password}<b>
          </p>
          <p>Caso tenha qualquer dúvida, não hesite em <a href="https://immortalis.com.br/#contact">entrar em contato</a> conosco.</p>
        `
        sendSmtpEmail.textContent = `
          Seja bem-vindo ao Immortalis. Utilize as seguintes informaçõees para acessar sua conta:
          Usuário: ${person.email}
          Senha: ${password}
        `
        sendSmtpEmail.to = [{ email: person.email }]
        smtpApi.sendTransacEmail(sendSmtpEmail).then(
          emailData => {
            // console.log(`API called successfully. Returned data: ${emailData}`)
            resolve(emailData)
          },
          error => {
            resolve(false)
          }
        )
      })

    if (firebase.auth.currentUser) {
      window.location.href = `/edit-memorial?id=${memorialId}`
    } else {
      const password = randomString(10)
      auth.createUserWithEmailAndPassword(userEmail, password).then(user => {
        const person = {
          email: userEmail,
          name: userName,
          roles: [],
          socialId: null,
          uid: user.user.uid,
        }
        firebase.user(person).then(() => {
          sendMail(person, password).then(() => {
            window.location.href = `/edit-memorial?id=${memorialId}`
          })
        })
      })
    }
  }

  updateToMemorial = e => {
    const { firebase, getStore, jumpToStep } = this.props
    const { firestore } = firebase
    const { eventYear, eventMonth, eventDay, personBirthday, postType } = this.state
    const { tributeIndex } = getStore()
    const firestoreSettings = {
      timestampsInSnapshots: true,
    }
    let eventDate = new Date(eventYear, eventMonth === 0 ? 1 : eventMonth - 1, eventDay)
    if (eventDate.getFullYear() < personBirthday.getFullYear()) {
      eventDate = null
    }
    firestore.settings(firestoreSettings)
    e.preventDefault()
    this.setState({
      hasSubmittedOnce: true,
    })
    if (this.isValidated()) {
      const data = getStore()
      const memorialItemData = {
        eventDate,
        eventDay,
        eventMonth,
        eventYear,
        image: data.image || '',
        message: data.message || '',
        quote: data.quote || '',
        quoteBackgroundColor: data.quoteBackgroundColor || '',
        quoteBackgroundImage: data.quoteBackgroundImage || '',
        quoteFontColor: data.quoteFontColor || '',
        type: postType,
        venueAddress: data.venueAddress || '',
        venueDateTime: data.venueDateTime || null,
        venueName: data.venueName || '',
        videoUrl: data.videoUrl || '',
      }

      const docRef = firestore.collection('Memorials').doc(data.memorialId)
      firestore.runTransaction(t =>
        t
          .get(docRef)
          .then(doc => {
            const obj = doc.get('tributes') ? doc.get('tributes') : []
            obj[tributeIndex] = memorialItemData
            t.set(docRef, { tributes: obj }, { merge: true })
          })
          .then(result => {
            confirmAlert({
              customUI: ({ onClose }) => (
                <div className="fixed top-0 bg-black-30 left-0 w-100 h-100 flex items-center justify-center sans-serif">
                  <div className="box pa3 w-90 w-50-m w-40-l tc">
                    <h1 className="f3 mb4">Memorial alterado com sucesso.</h1>
                    <div className="flex w-100 flex-wrap justify-center">
                      <button
                        type="button"
                        className="mh2 w-100 w-auto-ns mr2-ns btn btn-primary mb2 mb0-ns"
                        onClick={() => {
                          onClose()
                          jumpToStep(1)
                        }}
                      >
                        Voltar ao Memorial
                      </button>
                    </div>
                  </div>
                </div>
              ),
            })
          })
      )
    }
  }

  isValidated() {
    const userInput = this.grabUserInput()
    const { postType } = this.state
    const validateNewInput = this.validateData(userInput, postType)
    const { getStore, updateStore } = this.props
    let isDataValid = false

    if (Object.keys(validateNewInput).every(k => validateNewInput[k] === true)) {
      if (
        getStore().userEmail !== userInput.userEmail ||
        getStore().personName !== userInput.personName ||
        getStore().userName !== userInput.userName ||
        getStore().personCity !== userInput.personCity
      ) {
        updateStore({
          ...userInput,
          savedToCloud: false,
        })
      }
      isDataValid = true
    } else {
      this.setState(
        Object.assign(userInput, validateNewInput, this.validationErrors(validateNewInput))
      )
    }
    return isDataValid
  }

  validationCheck() {
    if (!this.validateOnDemand) return
    const { postType } = this.state
    const userInput = this.grabUserInput()
    const validateNewInput = this.validateData(userInput, postType)
    this.setState(
      Object.assign(userInput, validateNewInput, this.validationErrors(validateNewInput))
    )
  }

  selectQuoteBackground(obj, index) {
    const { updateStore } = this.props
    const stateObj = {
      quoteBackgroundColor: obj.backgroundColor,
      quoteBackgroundImage: obj.backgroundImage,
      quoteFontColor: obj.fontColor,
      selectedQuoteIndex: index,
    }
    this.setState(stateObj)
    updateStore(stateObj)
    this.validationCheck()
  }

  handleDateTimeChange = dateTimeValue => {
    const { updateStore } = this.props
    updateStore({
      venueDateTime: dateTimeValue,
    })
    this.setState({
      venueDateTime: dateTimeValue,
    })
  }

  resetState = () => {
    this.setState({
      action: 'selectPostType',
      eventDay: 0,
      eventMonth: 0,
      eventYear: 0,
      selectedQuoteIndex: 0,
      hasSubmittedOnce: false,
      message: '',
      postType: '',
      venueName: '',
      videoUrl: '',
    })
  }

  render() {
    const {
      action,
      daysInMonthOptions,
      eventDay,
      eventMonth,
      eventYear,
      hasSubmittedOnce,
      image,
      imageErrorMsg,
      imageValid,
      message,
      messageErrorMsg,
      messageValid,
      personBirthday,
      personDeathday,
      postType,
      quote,
      quoteBackgroundColor,
      quoteBackgroundImage,
      quoteErrorMsg,
      quoteFontColor,
      quoteValid,
      selectedQuoteIndex,
      venueAddress,
      venueAddressErrorMsg,
      venueAddressValid,
      venueDateTime,
      venueDateTimeErrorMsg,
      venueDateTimeValid,
      venueName,
      venueNameErrorMsg,
      venueNameValid,
      videoUrl,
      videoUrlValid,
      videoUrlErrorMsg,
    } = this.state

    const { jumpToStep, updateStore } = this.props

    const shouldShowImageError = imageValid === false
    const shouldShowMessageError = messageValid === false
    const shouldShowQuoteError = quoteValid === false
    const shouldShowVideoUrlError = videoUrlValid === false
    const shouldShowVenueAddressError = venueAddressValid === false && hasSubmittedOnce
    const shouldShowVenueDateTimeError = venueDateTimeValid === false && hasSubmittedOnce
    const shouldShowVenueNameError = venueNameValid === false && hasSubmittedOnce

    const shouldShowFuneralInfo = differenceInDays(new Date(), personDeathday) <= 7

    const shouldEnableButton =
      !shouldShowImageError &&
      !shouldShowMessageError &&
      !shouldShowQuoteError &&
      !shouldShowVideoUrlError &&
      !shouldShowVenueAddressError &&
      !shouldShowVenueDateTimeError &&
      !shouldShowVenueNameError

    const shouldEnableContentForm = action === 'addContent' || action === 'editContent'

    const shouldShowCemitery = ['FuneralInfo'].includes(postType)
    const shouldShowDateTime = ['FuneralInfo'].includes(postType)
    const shouldShowAddToTimeline = !['FuneralInfo'].includes(postType)
    const shouldShowPhoto = ['ImageOnly', 'ImageAndMessage'].includes(postType)
    const shouldShowQuote = ['QuoteAndMessage'].includes(postType)
    const shouldShowMessage = [
      'FuneralInfo',
      'ImageAndMessage',
      'MessageOnly',
      'QuoteAndMessage',
    ].includes(postType)
    const shouldShowVideoUrl = ['YoutubeVideo'].includes(postType)

    return (
      <section className="box tc ph2 w-100 pa3 pa4-ns mb3">
        <div className="mw8-ns center mt3-ns">
          <div>
            <form>
              <div className="flex flex-wrap w-100 center">
                {action === 'selectPostType' && (
                  <div className="w-100">
                    <h2 className="ma0 mb3 mb4-ns fw5 f4 tc w-100 lh-title ph3 f5 f4-ns">
                      O que você deseja adicionar?
                    </h2>
                    <div className="mb3 w-100">
                      <ul className="list flex flex-wrap ma0 pa0 justify-center w-100 bb b--mid-gray pb3 mb3">
                        <li className="pr3 w4 tc flex-shrink-0 flex-grow-0">
                          <div
                            role="button"
                            className="pointer outline-0 aspect-ratio aspect-ratio--1x1"
                            tabIndex={0}
                            onKeyPress={() => this.setState({ postType: 'ImageOnly' })}
                            onClick={() =>
                              this.setState({ action: 'addContent', postType: 'ImageOnly' })
                            }
                          >
                            <img
                              alt="Imagem"
                              src={PhotoImage}
                              className="w4 h4 ba b--dark-gray aspect-ratio--object h-auto"
                            />
                          </div>
                          <p className="f6 b">Imagem</p>
                        </li>
                        <li className="pr3 w4 tc flex-shrink-0 flex-grow-0">
                          <div
                            role="button"
                            className="pointer outline-0 aspect-ratio aspect-ratio--1x1"
                            tabIndex={0}
                            onKeyPress={() => this.setState({ postType: 'ImageAndMessage' })}
                            onClick={() =>
                              this.setState({ action: 'addContent', postType: 'ImageAndMessage' })
                            }
                          >
                            <img
                              alt="Imagem com Mensagem"
                              src={ImageAndMessageImage}
                              className="w4 h4 ba b--dark-gray aspect-ratio--object h-auto"
                            />
                          </div>
                          <p className="f6 b">Imagem com Mensagem</p>
                        </li>
                        <li className="pr3 w4 tc flex-shrink-0 flex-grow-0">
                          <div
                            role="button"
                            className="pointer outline-0 aspect-ratio aspect-ratio--1x1"
                            tabIndex={0}
                            onKeyPress={() => this.setState({ postType: 'MessageOnly' })}
                            onClick={() =>
                              this.setState({ action: 'addContent', postType: 'MessageOnly' })
                            }
                          >
                            <img
                              alt="Somente Mensagem"
                              src={MessageOnlyImage}
                              className="w4 h4 ba b--dark-gray aspect-ratio--object h-auto"
                            />
                          </div>
                          <p className="f6 b">Somente Mensagem</p>
                        </li>
                        <li className="pr3 w4 tc flex-shrink-0 flex-grow-0">
                          <div
                            role="button"
                            className="pointer outline-0 aspect-ratio aspect-ratio--1x1"
                            tabIndex={0}
                            onKeyPress={() => this.setState({ postType: 'QuoteAndMessage' })}
                            onClick={() =>
                              this.setState({ action: 'addContent', postType: 'QuoteAndMessage' })
                            }
                          >
                            <img
                              alt="Citação com Mensagem"
                              src={QuoteAndMessageImage}
                              className="w4 h4 ba b--dark-gray aspect-ratio--object h-auto"
                            />
                          </div>
                          <p className="f6 b">Citação com Mensagem</p>
                        </li>
                        <li className="pr3 w4 tc flex-shrink-0 flex-grow-0">
                          <div
                            role="button"
                            className="pointer outline-0 aspect-ratio aspect-ratio--1x1"
                            tabIndex={0}
                            onKeyPress={() => this.setState({ postType: 'YoutubeVideo' })}
                            onClick={() =>
                              this.setState({ action: 'addContent', postType: 'YoutubeVideo' })
                            }
                          >
                            <img
                              alt="Vídeo no Youtube"
                              src={YoutubeVideoImage}
                              className="w4 h4 ba b--dark-gray aspect-ratio--object h-auto"
                            />
                          </div>
                          <p className="f6 b">Link de Vídeo no Youtube</p>
                        </li>
                        {shouldShowFuneralInfo && (
                          <li className="pr3 w4 tc flex-shrink-0 flex-grow-0">
                            <div
                              role="button"
                              className="pointer outline-0 aspect-ratio aspect-ratio--1x1"
                              tabIndex={0}
                              onKeyPress={() => this.setState({ postType: 'FuneralInfo' })}
                              onClick={() =>
                                this.setState({ action: 'addContent', postType: 'FuneralInfo' })
                              }
                            >
                              <img
                                alt="Dados de Velório ou Sepultamento"
                                src={FuneralInfoImage}
                                className="w4 h4 ba b--dark-gray aspect-ratio--object h-auto"
                              />
                            </div>
                            <p className="f6 b">Dados de Velório ou Sepultamento</p>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                )}
                {shouldEnableContentForm && (
                  <div className="w-100">
                    <div className="w-100 flex flex-wrap mb3">
                      <div className="w-100 w-50-ns pr3-ns">
                        <h3>Conteúdo</h3>
                        {shouldShowQuote && [
                          <div key="quote-text" className="mb3 w-100">
                            <div className={`dropdown ${shouldShowQuoteError ? 'has-error' : ''}`}>
                              <select
                                id="quote"
                                className="filled pr4"
                                defaultValue={quote}
                                onChange={e => {
                                  this.setState({
                                    quote: e.target.options[e.target.selectedIndex].text,
                                  })
                                  updateStore({
                                    quote: e.target.options[e.target.selectedIndex].text,
                                  })
                                  const $this = this
                                  setTimeout(() => {
                                    $this.validationCheck()
                                  }, 100)
                                }}
                              >
                                {quotes.map((quoteGroup, idxQuoteGroup) => (
                                  <optgroup // eslint-disable-next-line react/no-array-index-key
                                    key={`quoteGroup-${idxQuoteGroup}`}
                                    label={quoteGroup.type}
                                  >
                                    {quoteGroup.quotes.map((quoteItem, idxQuote) => (
                                      // eslint-disable-next-line react/no-array-index-key
                                      <option key={`quote-${idxQuote}`} value={quoteItem}>
                                        {quoteItem}
                                      </option>
                                    ))}
                                  </optgroup>
                                ))}
                              </select>
                              <label htmlFor="quote">Citação</label>
                              {shouldShowQuoteError && <FormInlineError error={quoteErrorMsg} />}
                            </div>
                          </div>,
                          <div
                            id="quote-background"
                            key="quote-background"
                            className="mb3 w-100 tl relative bw1 b--mid-gray br2 pa2 ba"
                          >
                            <label htmlFor="quote-background" className="db f7 dark-gray">
                              Fundo da Citação
                            </label>
                            <ul className="ma0 list pa0 nowrap overflow-x-scroll">
                              {quotesBackgrounds.map((quotesBackground, idx) => (
                                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                                <li
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={idx}
                                  className={`
                                    cover pointer dib ba br1 b--mid-gray w3 h3 bg-blue
                                    ${idx === 0 ? 'mr1 mv1' : 'ma1'}
                                    ${
                                      quotesBackground.backgroundColor === quoteBackgroundColor
                                        ? 'ba bw2 b--orange'
                                        : ''
                                    }
                                  `}
                                  style={{
                                    backgroundColor: quotesBackground.backgroundColor,
                                    fontColor: quotesBackground.fontColor,
                                    backgroundImage: quotesBackground.backgroundImage
                                      ? `url(${quotesBackground.backgroundImage})`
                                      : null,
                                  }}
                                  onKeyDown={() => {
                                    this.selectQuoteBackground(quotesBackgrounds[idx], idx)
                                  }}
                                  onClick={() => {
                                    this.selectQuoteBackground(quotesBackgrounds[idx], idx)
                                  }}
                                />
                              ))}
                            </ul>
                          </div>,
                        ]}

                        {shouldShowMessage && (
                          <div className="mb3 w-100">
                            <div
                              className={`textarea ${shouldShowMessageError ? 'has-error' : ''}`}
                            >
                              <textarea
                                ref={c => {
                                  this.message = c
                                }}
                                id="message"
                                className="filled lh-copy f6"
                                defaultValue={message}
                                onBlur={this.validationCheck}
                                onChange={e => {
                                  this.onMessageChange(e.target.value)
                                }}
                              />
                              <label htmlFor="message">Mensagem</label>
                              {shouldShowMessageError && (
                                <FormInlineError error={messageErrorMsg} />
                              )}
                            </div>
                          </div>
                        )}

                        {shouldShowPhoto && (
                          <div className="mb3 w-100">
                            <Dropzone accept="image/*" onDrop={this.onDrop} multiple={false}>
                              {({ getRootProps, getInputProps, isDragActive }) => (
                                <div
                                  {...getRootProps()}
                                  className={`relative pointer b--dashed br3 bw1 outline-0 ${
                                    isDragActive ? 'b--red' : ''
                                  } ${shouldShowImageError ? 'b--red' : 'b--mid-gray'}`}
                                >
                                  <input {...getInputProps()} />
                                  {isDragActive ? (
                                    <p>Solte seu arquivo aqui...</p>
                                  ) : (
                                    <p>Clique aqui para adicionar uma imagem&hellip;</p>
                                  )}
                                  {shouldShowImageError && (
                                    <FormInlineError error={imageErrorMsg} />
                                  )}
                                </div>
                              )}
                            </Dropzone>
                          </div>
                        )}

                        {shouldShowVideoUrl && (
                          <div className="mb3 w-100 relative">
                            <div className={`input ${shouldShowVideoUrlError ? 'has-error' : ''}`}>
                              <input
                                id="videoUrl"
                                ref={c => {
                                  this.videoUrl = c
                                }}
                                type="text"
                                defaultValue={videoUrl}
                                className={`${videoUrl !== '' ? 'filled' : ''}`}
                                onChange={event => {
                                  this.setState({ [event.target.id]: event.target.value })
                                  updateStore({ [event.target.id]: event.target.value })
                                  this.validationCheck()
                                }}
                              />
                              <label htmlFor="sender">Link do vídeo no YouTube</label>
                            </div>
                            {shouldShowVideoUrlError && (
                              <FormInlineError
                                error={videoUrlErrorMsg}
                                style={{ maxWidth: '400px' }}
                              />
                            )}
                          </div>
                        )}

                        {shouldShowCemitery && [
                          <div key="venueName" className="mb3 w-100">
                            <div className={`input ${shouldShowVenueNameError ? 'has-error' : ''}`}>
                              <input
                                defaultValue={venueName}
                                ref={c => {
                                  this.venueName = c
                                }}
                                id="venueName"
                                className={`${venueName !== '' ? 'filled' : ''}`}
                                onBlur={this.validationCheck}
                                onChange={e => {
                                  this.onInputChange(e.target.value, e.target.id)
                                }}
                              />
                              <label htmlFor="venueName">Cemitério / Sala de Velório</label>
                              {shouldShowVenueNameError && (
                                <FormInlineError error={venueNameErrorMsg} />
                              )}
                            </div>
                          </div>,
                          <div key="venueAddress" className="mb3 w-100">
                            <div
                              className={`input ${shouldShowVenueAddressError ? 'has-error' : ''}`}
                            >
                              <input
                                defaultValue={venueAddress}
                                ref={c => {
                                  this.venueAddress = c
                                }}
                                id="venueAddress"
                                className={`${venueAddress !== '' ? 'filled' : ''}`}
                                onBlur={this.validationCheck}
                                onChange={e => {
                                  this.onInputChange(e.target.value, e.target.id)
                                }}
                              />
                              <label htmlFor="venueAddress">Endereço do Cemitério / Velório</label>
                              {shouldShowVenueAddressError && (
                                <FormInlineError error={venueAddressErrorMsg} />
                              )}
                            </div>
                          </div>,
                        ]}
                        {shouldShowDateTime && (
                          <div className="mb3 w-100 relative">
                            <DatePicker
                              selected={venueDateTime}
                              onChange={this.handleDateTimeChange}
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              dateFormat={`d 'de' MMMM 'de' yyyy, 'às' H:mm`}
                              timeCaption="Hora"
                              locale="ptBR"
                              placeholderText="Data e Horário"
                              className={`b--solid bw1 br2 ${
                                shouldShowVenueDateTimeError ? 'b--red' : 'b--mid-gray'
                              }`}
                            />
                            {shouldShowVenueDateTimeError && (
                              <FormInlineError error={venueDateTimeErrorMsg} />
                            )}
                          </div>
                        )}
                        {shouldShowAddToTimeline && (
                          <div className="mb3 mt4 w-100 relative tl">
                            <div className="w-100 flex justify-between mb2">
                              <p className="b ma0">Deseja adicionar à linha do tempo?</p>
                              <div className="relative hide-child">
                                <span
                                  className="material-icons dark-gray"
                                  style={{ cursor: 'default' }}
                                >
                                  info_outline
                                </span>
                                <div
                                  className="child absolute z-max context pa2 f7 tr right-0 mt1 ws-normal lh-copy"
                                  style={{ width: '300px' }}
                                  data-pos="right"
                                >
                                  <div>
                                    Ao adicionar uma data a este evento, ele será disponibilizado na
                                    linha do tempo da pessoa homenageada.
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-100 flex justify-between">
                              <div className="pr2 w-third">
                                <div className="dropdown">
                                  <select
                                    id="eventYear"
                                    className="filled pr4"
                                    defaultValue={eventYear}
                                    onChange={e => {
                                      if (e.target.selectedIndex === 0) {
                                        document.getElementById('eventMonth').selectedIndex = 0
                                        document.getElementById('eventDay').selectedIndex = 0
                                        this.setState({ eventMonth: 0, eventDay: 0 })
                                      }
                                      this.setState({
                                        eventYear: parseInt(
                                          e.target.options[e.target.selectedIndex].text,
                                          0
                                        ),
                                      })
                                      updateStore({
                                        eventYear: parseInt(
                                          e.target.options[e.target.selectedIndex].text,
                                          0
                                        ),
                                      })
                                      const $this = this
                                      setTimeout(() => {
                                        $this.validationCheck()
                                      }, 100)
                                    }}
                                  >
                                    <option />
                                    {range(
                                      personBirthday.getFullYear(),
                                      personDeathday.getFullYear()
                                    ).map(item => (
                                      <option value={item} key={item}>
                                        {item}
                                      </option>
                                    ))}
                                  </select>
                                  <label htmlFor="eventYear">Ano</label>
                                </div>
                              </div>
                              <div className="pr2 w-third">
                                <div className="dropdown">
                                  <select
                                    id="eventMonth"
                                    defaultValue={eventMonth}
                                    className="filled pr4"
                                    disabled={eventYear === ''}
                                    onChange={e => {
                                      this.setState({
                                        eventMonth: parseInt(
                                          e.target.options[e.target.selectedIndex].value,
                                          0
                                        ),
                                        daysInMonthOptions: daysInMonth(
                                          e.target.options[e.target.selectedIndex].value,
                                          eventYear
                                        ),
                                      })
                                      updateStore({
                                        eventMonth: e.target.options[e.target.selectedIndex].value,
                                      })
                                      const $this = this
                                      setTimeout(() => {
                                        $this.validationCheck()
                                      }, 100)
                                    }}
                                  >
                                    <option value={0} />
                                    <option value={1}>Janeiro</option>
                                    <option value={2}>Fevereiro</option>
                                    <option value={3}>Março</option>
                                    <option value={4}>Abril</option>
                                    <option value={5}>Maio</option>
                                    <option value={6}>Junho</option>
                                    <option value={7}>Julho</option>
                                    <option value={8}>Agosto</option>
                                    <option value={9}>Setembro</option>
                                    <option value={10}>Outubro</option>
                                    <option value={11}>Novembro</option>
                                    <option value={12}>Dezembro</option>
                                  </select>
                                  <label htmlFor="eventMonth">Mês</label>
                                </div>
                              </div>
                              <div className="w-third">
                                <div className="dropdown">
                                  <select
                                    id="eventDay"
                                    className="filled pr4"
                                    defaultValue={eventDay}
                                    disabled={eventMonth === 0}
                                    onChange={e => {
                                      this.setState({
                                        eventDay: parseInt(
                                          e.target.options[e.target.selectedIndex].text,
                                          0
                                        ),
                                      })
                                      updateStore({
                                        eventDay: parseInt(
                                          e.target.options[e.target.selectedIndex].text,
                                          0
                                        ),
                                      })
                                      const $this = this
                                      setTimeout(() => {
                                        $this.validationCheck()
                                      }, 100)
                                    }}
                                  >
                                    <option value="" />
                                    {Array.from(Array(daysInMonthOptions).keys()).map(item => (
                                      <option key={item + 1}>{item + 1}</option>
                                    ))}
                                  </select>
                                  <label htmlFor="eventDay">Dia</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="w-100 w-50-ns pl2-ns">
                        <h3>Visualização</h3>
                        {postType === 'ImageAndMessage' && (
                          <ImageAndMessage
                            message={message}
                            image={image}
                            type="memorial"
                            sender={null}
                          />
                        )}
                        {postType === 'FuneralInfo' && (
                          <FuneralInfo
                            message={message}
                            sender={null}
                            venueAddress={venueAddress}
                            venueDateTime={venueDateTime}
                            venueName={venueName}
                          />
                        )}
                        {postType === 'MessageOnly' && (
                          <MessageOnly message={message} type="memorial" />
                        )}
                        {postType === 'QuoteAndMessage' && (
                          <QuoteAndMessage
                            quote={quote}
                            message={message}
                            quoteBackgroundColor={quoteBackgroundColor}
                            quoteBackgroundImage={quoteBackgroundImage}
                            quoteFontColor={quoteFontColor}
                            selectedQuoteIndex={selectedQuoteIndex}
                            type="memorial"
                          />
                        )}
                        {postType === 'ImageOnly' && <ImageOnly image={image} />}
                        {postType === 'YoutubeVideo' && (
                          <YoutubeVideo
                            videoUrl={videoUrl}
                            setYoutubeVideoValidity={this.setYoutubeVideoValidity}
                          />
                        )}
                      </div>
                    </div>
                    <div className="w-100 flex flex-wrap">
                      <div className="w-100 w-50-ns pr2-ns tl">
                        {action === 'addContent' && (
                          <button
                            type="button"
                            onClick={e => this.setState({ action: 'selectPostType' })}
                            className="btn btn-primary btn-primary-outline"
                          >
                            Alterar Tipo de Publicação
                          </button>
                        )}
                        {action === 'editContent' && (
                          <button
                            type="button"
                            onClick={() => jumpToStep(1)}
                            className="btn btn-primary btn-primary-outline"
                          >
                            Voltar
                          </button>
                        )}
                      </div>
                      <div className="w-100 w-50-ns pl2-ns tr">
                        {action === 'addContent' && (
                          <button
                            type="button"
                            onClick={e => this.addToMemorial(e)}
                            className="btn btn-primary"
                            disabled={!shouldEnableButton}
                          >
                            Adicionar ao Memorial
                          </button>
                        )}
                        {action === 'editContent' && (
                          <button
                            type="button"
                            onClick={e => this.updateToMemorial(e)}
                            className="btn btn-primary"
                            disabled={!shouldEnableButton}
                          >
                            Salvar Alterações
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </form>
            {action === 'contentAdded' && (
              <div className="w-100 mv4">
                <h2 className="ma0 mb3 mb4-ns fw5 f4 tc w-100 lh-title ph3 f5 f4-ns">
                  Conteúdo adicionado ao memorial.
                </h2>
                <p className="w-100 mw6-ns tc center lh-copy mb4">
                  Você pode continuar a inclusão de conteúdos para o memorial ou, caso tenha,
                  terminado, pode proceder para o pagamento e publicação.
                </p>
                <div className="flex w-100 flex-wrap flex-nowrap-ns justify-center">
                  <div className="pr2-ns w-100 w-auto-ns mb3">
                    <button
                      type="button"
                      className="btn btn-primary btn-primary-outline lh-title w-100 w-auto-ns"
                      onClick={() => this.resetState()}
                    >
                      Adicionar mais Conteúdo
                    </button>
                  </div>
                  <div className="pl2-ns w-100 w-auto-ns mb3">
                    <button
                      type="button"
                      className="btn btn-primary lh-title w-100 w-auto-ns"
                      onClick={this.visualizeAndPublish}
                    >
                      Visualizar e Publicar
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    )
  }
}

export default withFirebase(Step3)
