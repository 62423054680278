const quotesBackgrounds = [
  {
    backgroundColor: null,
    fontColor: 'white',
    backgroundImage:
      'https://firebasestorage.googleapis.com/v0/b/immortalis.appspot.com/o/site%2Ftexto-imagem.jpg?alt=media&token=063ba1a8-fb35-48f0-9740-02bea88a9f67',
  },
  {
    backgroundColor: 'red',
    fontColor: 'white',
    backgroundImage: null,
  },
  {
    backgroundColor: 'green',
    fontColor: 'white',
    backgroundImage: null,
  },
  {
    backgroundColor: 'blue',
    fontColor: 'white',
    backgroundImage: null,
  },
  {
    backgroundColor: 'white',
    fontColor: 'black',
    backgroundImage: null,
  },
  {
    backgroundColor: 'black',
    fontColor: 'white',
    backgroundImage: null,
  },
]
const quotes = [
  {
    type: 'Geral',
    quotes: [
      'A amizade sincera é termo gravado na lápide do tempo',
      'A morte deixa uma ferida incurável, mas o amor deixa uma memória inesquecível',
      'A saudade é o que faz as coisas pararem no Tempo.',
      'Adeus amigos, eu vou embora por um tempo, vou escrever meus versos na lua e procurar o meu amor nas estrelas.',
      'As pedras que surgiram ao longo de meu caminho com esforço as lapidei e transformei em jóias.',
      'Eu nunca digo adeus a ninguém. Eu nunca deixo as pessoas mais próximas de mim partirem. Eu os levo comigo onde quer que eu vá.',
      'Não deixe que seu nome seja gravado só na sua lápide, mas também na história.',
      'Obrigado por me amar como ninguém.',
      'Para sempre em nossos corações',
      'Um milhão de palavras não podem fazer você voltar. Eu sei, porque eu tentei. Nem um milhão de lágrimas também. Eu sei porque chorei até não poder mais.',
    ],
  },
  {
    type: 'Avós',
    quotes: [
      'Avós nunca morrem, eles apenas se tornam invisíveis. Você só tem de escutá-los com seu coração.',
      'Avós são mágicos que criam memórias maravilhosas para seus netos.',
      'Obrigado por cuidar de mim, por me ensinar e por me apoiar incondicionalmente.',
      'Saia do céu por um tempo e venha me abraçar.',
      'Você não me deu a vida, mas você me ensinou a viver isso. Eu sempre te levarei em meu coração.',
    ],
  },
  {
    type: 'Mães',
    quotes: [
      'Guardaremos em nossos corações o seu melhor sorriso.',
      'Mãe é o pilar da família, aquela que dá tudo sem esperar nada em troca.',
      'Sua partida foi inesperada e dolorosa, mas sua memória sempre viverá em nossos corações.',
      'Você era amor, doçura e consolo. Para sempre estará no coração de seus filhos.',
      'Você nunca saberá o valor de uma mãe, até que tudo o que resta seja a sua memória.',
      'Você passou como uma estrela cadente em nossas vidas, mas sua luz vai brilhar para sempre em nossos corações.',
      'Você vai viver na memória do seu marido e filhos.',
    ],
  },
  {
    type: 'Pais',
    quotes: [
      'Agora repousa um homem que passou pela vida iluminando-a com paixão, poesia e amor.',
      'Eu tenho o seu rosto e a sua voz em mente e eu sempre os terei, mesmo que o tempo passe e você não esteja entre nós.',
      'O amor de um pai não conhece limites e você foi o melhor exemplo disso.',
      'Obrigado pai por ter me dado o melhor presente que pode se dar a uma pessoa: sabedoria.',
      'Pai, você merece um monumento por estar presente em todos os momentos.',
      'Porque eu devo a você muitas das minhas conquistas. Obrigado por ter ajudar a ser quem sou.',
      'Você vai viver na memória de sua esposa e filhos.',
    ],
  },
]

export { quotes, quotesBackgrounds }
