import React, { Component } from 'react'

class MemorialNavBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: props.activeTab || 'memories',
      shouldShowPublicTributes: props.shouldShowPublicTributes || false,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...nextProps,
    })
  }

  handleTabChange = ev => {
    const { handleTabChange } = this.props
    const activeTab = ev.target.getAttribute('data-tab')
    this.setState({
      activeTab,
    })
    handleTabChange(activeTab)
  }

  render() {
    const { activeTab, shouldShowPublicTributes } = this.state
    return (
      <nav className="ma0 pa0 cf">
        <ul className="list ma0 mt3 pa2 w-100 flex items-center h2 pl3 pl4-ns pt3 f6 cf mb2 flex-nowrap overflow-y-hidden overflow-x-auto pb4">
          <li
            className={`bb pl0 pv2 mr3 mr4-ns nowrap ${
              activeTab === 'memories' ? 'b--orange orange' : 'b--transparent'
            }`}
            style={{ borderWidth: '4px' }}
          >
            <div
              className="pointer outline-0"
              role="button"
              tabIndex={0}
              data-tab="memories"
              onClick={this.handleTabChange}
              onKeyPress={this.handleTabChange}
            >
              Memórias
            </div>
          </li>
          <li
            className={`bb pl0 pv2 mr3 mr4-ns nowrap ${
              activeTab === 'timeline' ? 'b--orange orange' : 'b--transparent'
            }`}
            style={{ borderWidth: '4px' }}
          >
            <div
              className="pointer outline-0"
              role="button"
              tabIndex={0}
              data-tab="timeline"
              onClick={this.handleTabChange}
              onKeyPress={this.handleTabChange}
            >
              Linha do Tempo
            </div>
          </li>
          {shouldShowPublicTributes && (
            <li
              className={`bb pl0 pv2 mr3 mr4-ns nowrap
                ${activeTab === 'public-tributes' ? 'b--orange orange' : 'b--transparent'}
              `}
              style={{ borderWidth: '4px' }}
            >
              <div
                className="pointer outline-0"
                role="button"
                tabIndex={0}
                data-tab="public-tributes"
                onClick={this.handleTabChange}
                onKeyPress={this.handleTabChange}
              >
                Homenagens Públicas
              </div>
            </li>
          )}
          <li
            className={`bb pl0 pv2 nowrap ${
              activeTab === 'guestbook' ? 'b--orange orange' : 'b--transparent'
            }`}
            style={{ borderWidth: '4px' }}
          >
            <div
              className="pointer outline-0"
              role="button"
              tabIndex={0}
              data-tab="guestbook"
              onClick={this.handleTabChange}
              onKeyPress={this.handleTabChange}
            >
              Livro de Visitas
            </div>
          </li>
        </ul>
      </nav>
    )
  }
  // <nav className="ma0 pa0 cf">
  //   <ul className="list ma0 pa0 w-100 flex items-center h2 pl3 pt3 f6 cf mb2">
  //     <li
  //       className="bb pl0 pt2 pb1 b--orange orange mr2 mr4-ns"
  //       style={{ borderWidth: '4px' }}
  //     >
  //       Linha do Tempo
  //     </li>
  //     <li
  //       className="bb pl0 pt2 pb1 mr2 mr4-ns"
  //       style={{ borderWidth: '4px', borderColor: 'transparent' }}
  //     >
  //       Memórias
  //     </li>
  //     <li
  //       className="bb pl0 pt2 pb1 mr4-ns"
  //       style={{ borderWidth: '4px', borderColor: 'transparent' }}
  //     >
  //       Livro de Visitas
  //     </li>
  //   </ul>
  // </nav>
}

export default MemorialNavBar
