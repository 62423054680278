import React, { Component } from 'react'

import MemorialHeader from './templates/MemorialHeader'
import { withFirebase } from '../firebase/context'

class Step2 extends Component {
  constructor(props) {
    super(props)
    const { getStore } = props
    this.state = {
      hasSubmittedOnce: false,
      memorialBackgroundColor: '#dadedf',
      memorialIntroduction: 'Clique aqui e adicione o texto introdutório do memorial.',
      personBirthday: getStore().personBirthday,
      personCity: getStore().personCity,
      personDeathday: getStore().personDeathday,
      personName: getStore().personName,
      personPhoto: getStore().personPhoto,
      shouldShowImageError: false,
      shouldShowMemorialIntroductionError: false,
      personId: getStore().personId,
    }
    props.updateStore({
      ...this.state,
    })

    this.handleMemorialIntroductionChange = this.handleMemorialIntroductionChange.bind(this)
    this.handlePersonPhotoChange = this.handlePersonPhotoChange.bind(this)
  }

  handleHeaderBackgroundColorChange = color => {
    const { updateStore } = this.props
    this.setState({
      memorialBackgroundColor: color.hex,
    })
    updateStore({
      memorialBackgroundColor: color.hex,
    })
  }

  handlePersonPhotoChange = personPhoto => {
    this.setState({ personPhoto })
  }

  handleMemorialIntroductionChange = memorialIntroduction => {
    this.setState({ memorialIntroduction })
  }

  goToNextStep = e => {
    const { jumpToStep } = this.props
    if (this.isValidated()) {
      const { firebase, getStore, updateStore } = this.props
      const { firestore } = firebase
      const firestoreSettings = {
        timestampsInSnapshots: true,
      }
      firestore.settings(firestoreSettings)
      const data = getStore()
      const memorialData = {
        available: false,
        guests: [],
        memorialBackgroundColor: data.memorialBackgroundColor,
        memorialIntroduction: data.memorialIntroduction,
        person: data.person,
        personPhoto: data.personPhoto,
        preApprovalRequired: false,
        socialPosts: [],
        tributes: [],
        userEmail: data.userEmail,
      }

      const addMemorial = memorial =>
        new Promise((resolve, reject) => {
          firestore
            .collection('Memorials')
            .add(memorial)
            .then(addedMemorial => {
              const personRef = firestore.collection('People').doc(getStore().personId)
              personRef
                .set(
                  {
                    memorial: addedMemorial,
                  },
                  { merge: true }
                )
                .then(updatedPerson => {
                  resolve(addedMemorial)
                })
            })
        })

      addMemorial(memorialData).then(memorialKey => {
        // console.log(memorialKey.id)
        updateStore({
          memorialId: memorialKey.id,
        })
        jumpToStep(2)
      })
    } else {
      // console.log('not valid')
    }
  }

  goToPreviousStep = e => {
    const { jumpToStep } = this.props
    e.preventDefault()
    jumpToStep(0)
  }

  validateData = data => ({
    memorialBackgroundColorVal: !!data.memorialBackgroundColor,
    memorialIntroductionVal:
      data.memorialIntroduction !== 'Clique aqui e adicione o texto introdutório do memorial.' &&
      data.memorialIntroduction !== '',
    personBirthdayVal: !!data.personBirthday,
    personCityVal: data.personCity !== '' && data.personCity !== undefined,
    personDeathdayVal: !!data.personDeathday,
    personNameVal: data.personName !== '' && data.personName.indexOf(' ') !== -1,
    personPhotoVal: !!data.personPhoto,
  })

  validationErrors = val => {
    const errMsgs = {
      memorialIntroductionValMsg: val.memorialIntroductionVal
        ? ''
        : 'Digite o texto introdutório do memorial.',
      personPhotoValMsg: val.personPhotoVal ? '' : 'Adicione uma foto da pessoa homenageada',
    }
    return errMsgs
  }

  isValidated() {
    const userInput = this.grabUserInput()
    const validateNewInput = this.validateData(userInput)
    const { getStore, updateStore } = this.props
    let isDataValid = false

    this.setState({
      shouldShowImageError: !validateNewInput.personPhotoVal,
      shouldShowMemorialIntroductionError: !validateNewInput.memorialIntroductionVal,
    })

    if (Object.keys(validateNewInput).every(k => validateNewInput[k] === true)) {
      if (
        getStore().personBirthday !== userInput.personBirthday ||
        getStore().memorialBackgroundColor !== userInput.memorialBackgroundColor ||
        getStore().memorialIntroduction !== userInput.memorialIntroduction ||
        getStore().personBirthday !== userInput.personBirthday ||
        getStore().personCity !== userInput.personCity ||
        getStore().personName !== userInput.personName
      ) {
        updateStore({
          ...userInput,
          savedToCloud: false,
        })
      }
      isDataValid = true
    } else {
      this.setState(
        Object.assign(userInput, validateNewInput, this.validationErrors(validateNewInput))
      )
    }
    return isDataValid
  }

  grabUserInput() {
    const {
      memorialBackgroundColor,
      memorialIntroduction,
      personBirthday,
      personCity,
      personDeathday,
      personName,
    } = this.state
    const { getStore } = this.props
    const { personPhoto } = getStore()
    return {
      memorialBackgroundColor,
      memorialIntroduction,
      personBirthday,
      personCity,
      personDeathday,
      personPhoto,
      personName,
    }
  }

  render() {
    const {
      memorialBackgroundColor,
      personBirthday,
      personDeathday,
      personId,
      personPhoto,
      personName,
      shouldShowImageError,
      shouldShowMemorialIntroductionError,
    } = this.state
    const { updateStore } = this.props
    return (
      <section className="box tc w-100 mb3">
        <div className="mw8-ns center mt3-ns">
          <div className="">
            <form>
              <div className="flex flex-wrap w-50-ns center">
                <h2 className="ma3 ma4-ns mb3 mb4-ns fw5 f4 tc w-100 lh-title ph3 f5 f4-ns">
                  {/* ph2 pa3 pa4-ns  */}
                  Defina o cabeçalho do memorial
                </h2>
              </div>
              <MemorialHeader
                headerBackgroundColor={memorialBackgroundColor}
                handleHeaderBackgroundColorChange={this.handleHeaderBackgroundColorChange}
                handleMemorialIntroductionChange={this.handleMemorialIntroductionChange}
                handlePersonPhotoChange={this.handlePersonPhotoChange}
                isPreview
                isEditing
                name={personName}
                personBirthday={personBirthday}
                personDeathday={personDeathday}
                personPhoto={personPhoto}
                shouldShowImageError={shouldShowImageError}
                personId={personId}
                shouldShowMemorialIntroductionError={shouldShowMemorialIntroductionError}
                updateStore={updateStore}
              />
            </form>
          </div>
          <div className="mt3 flex flex-wrap justify-between flex-column-reverse flex-row-ns pa2 pa3-ns">
            <div className="w-100 w-50-ns tl mb2">
              <button
                type="button"
                className="btn btn-primary btn-small btn-primary-outline lh-title w-100 w-auto-ns"
                onClick={e => this.goToPreviousStep(e)}
              >
                Voltar
              </button>
            </div>
            <div className="w-100 w-50-ns tr mb2 mt4 mt0-ns">
              <button
                type="button"
                className="btn btn-primary btn-small btn-primary lh-title w-auto mt3 mt0-ns"
                onClick={e => this.goToNextStep(e)}
              >
                Adicione Conteúdo ao Memorial
              </button>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default withFirebase(Step2)
