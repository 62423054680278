import React from 'react'
import PaypalBtn from 'react-paypal-checkout'

const PaypalButton = ({ ...props }) => {
  const { onClick, price } = props
  const onSuccess = payment => {
    // console.log('The payment was succeeded!', payment)
    onClick()
  }

  const onCancel = data => {
    // User pressed "cancel" or close Paypal's popup!
    // console.log('The payment was cancelled!', data)
  }

  const onError = err => {
    // The main Paypal's script cannot be loaded or somethings block the loading of that script!
    // console.log('Error!', err)
  }

  const currency = 'BRL'
  const env = process.env.NODE_ENV === 'production' ? 'production' : 'sandbox'
  const locale = 'pt_BR'
  const total = price
  const style = {
    color: 'gold',
    label: 'pay',
    shape: 'rect',
    size: 'responsive',
    tagline: false,
  }

  const client = {
    sandbox: process.env.GATSBY_PAYPAL_SANDBOX_CLIENT_ID,
    production: process.env.GATSBY_PAYPAL_PRODUCTION_CLIENT_ID,
  }

  return (
    <div className="w-100">
      <PaypalBtn
        env={env}
        client={client}
        currency={currency}
        total={total}
        locale={locale}
        style={style}
        onError={onError}
        onSuccess={onSuccess}
        onCancel={onCancel}
      />
    </div>
  )
}

export default PaypalButton
