import React from 'react'

const MessageOnly = props => {
  const { allowEdit, isPreview, type } = props
  let { message, sender } = props
  message =
    message ||
    (type === 'memorial'
      ? 'Digite a mensagem que deseja adicionar ao memorial.'
      : `Informamos com pesar o falecimento do nosso (grande amigo ou tio/pai/filho etc. querido)
        (nome). Sua morte nos deixou muito surpresos, mas esperamos que ele/ela possa estar em um lugar melhor.`)
  sender = sender || (type === 'memorial' ? null : 'Nome da Família')

  const handleEditTributeAction = () => {
    const { handleEditTribute, tributeIndex } = props
    handleEditTribute('MessageOnly', { tributeIndex, ...props })
  }

  const handleDeleteTributeAction = () => {
    const { handleDeleteTribute, tributeIndex } = props
    handleDeleteTribute(tributeIndex)
  }

  return (
    <section
      className={`${
        type === 'memorial' || (type === 'memorial' && !isPreview) ? 'bg-light-gray' : 'bg-white'
      } br3 flex-auto flex-column flex justify-center w-100 h-auto relative`}
    >
      <div className={`ma3 ma5-ns f6 f5-ns ${isPreview ? 'mid-gray' : ''}`}>
        <p
          className="ma0 tc lh-copy"
          style={{
            whiteSpace: 'pre-line',
          }}
        >
          {message}
        </p>
        {sender && <p className="b i mb3">{sender}</p>}
      </div>
      {allowEdit && (
        <div className="absolute bottom-0 right-0 ma2 flex justify-end bg-white-90 br2">
          <div
            role="button"
            tabIndex={0}
            className="material-icons pointer pa1"
            title="Excluir"
            onClick={handleDeleteTributeAction}
            onKeyDown={handleDeleteTributeAction}
          >
            delete
          </div>
          <div
            role="button"
            tabIndex={0}
            className="material-icons pointer pa1"
            title="Alterar"
            onClick={handleEditTributeAction}
            onKeyDown={handleEditTributeAction}
          >
            edit
          </div>
        </div>
      )}
    </section>
  )
}

export default MessageOnly
