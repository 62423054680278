import React from 'react'
import ptBR from 'date-fns/locale/pt-BR'
import { format } from 'date-fns'

const FuneralInfo = props => {
  let { message, venueAddress, venueDateTime, venueName } = props
  const isPreview = !message
  const { allowEdit, sender } = props
  message = (
    message ||
    `Informamos com pesar o falecimento do nosso (grande amigo ou tio/pai/filho etc. querido)
    (nome). Sua morte nos deixou muito surpresos, mas esperamos que ele/ela possa estar em um lugar melhor.
    Uma cerimônia de despedida de nosso querido amigo será realizada na próxima quarta-feira.`
  ).trim()
  venueName = (venueName || `Nome do cemitério ou velório`).trim()
  venueAddress = (venueAddress || `Endereço do cemitério ou velório`).trim()
  venueDateTime = (venueDateTime
    ? format(venueDateTime, `d 'de' MMMM 'de' yyyy, 'às' H:mm`, {
        awareOfUnicodeTokens: true,
        locale: ptBR,
      })
    : `31/12/2019, às 14:30`
  ).trim()

  const handleDeleteTributeAction = () => {
    const { handleDeleteTribute, tributeIndex } = props
    handleDeleteTribute(tributeIndex)
  }

  const handleEditTributeAction = () => {
    const { handleEditTribute, tributeIndex } = props
    handleEditTribute('FuneralInfo', { tributeIndex, ...props })
  }

  return (
    <section className="lh-copy bg-light-gray br3 overflow-hidden flex-auto flex-column flex tl pa3 relative">
      {message && (
        <p
          className="tl f6 f5-ns"
          style={{
            whiteSpace: 'pre-line',
          }}
        >
          {message}
        </p>
      )}
      <dl className="ma0 f7">
        {venueName && [
          <dt key="venueNameTerm" className="b ttu f7 dark-gray">
            Local
          </dt>,
          <dd key="venueNameData" className="ma0 mb3 b">
            {venueName}
          </dd>,
        ]}
        {venueAddress && [
          <dt key="venueAddressTerm" className="b ttu f7 dark-gray">
            Endereço
          </dt>,
          <dd key="venueAddressData" className="ma0 mb3 b">
            {venueAddress}
          </dd>,
        ]}
        {venueDateTime && [
          <dt key="venueDateTimeTerm" className="b ttu f7 dark-gray">
            Data e Horário
          </dt>,
          <dd key="venueDateTimeData" className="ma0 mb3 b">
            {venueDateTime}
          </dd>,
        ]}
      </dl>
      <p className="ma0 i">{sender}</p>
      {allowEdit && (
        <div className="absolute bottom-0 right-0 ma2 flex justify-end bg-white-90 br2">
          <div
            role="button"
            tabIndex={0}
            className="material-icons pointer pa1"
            title="Excluir"
            onClick={handleDeleteTributeAction}
            onKeyDown={handleDeleteTributeAction}
          >
            delete
          </div>
          <div
            role="button"
            tabIndex={0}
            className="material-icons pointer pa1"
            title="Alterar"
            onClick={handleEditTributeAction}
            onKeyDown={handleEditTributeAction}
          >
            edit
          </div>
        </div>
      )}
    </section>
  )
}

export default FuneralInfo
