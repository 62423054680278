import React from 'react'
import PhotoImage from '../../images/Photo.png'

const ImageOnly = props => {
  const { allowEdit, alt, image } = props

  const handleDeleteTributeAction = () => {
    const { handleDeleteTribute, tributeIndex } = props
    handleDeleteTribute(tributeIndex)
  }

  if (!image) {
    return (
      <div className="outline-0 aspect-ratio aspect-ratio--1x1 o-30">
        <img alt="Imagem" src={PhotoImage} className="aspect-ratio--object h-auto" />
      </div>
    )
  }

  return (
    <section className="bg-light-gray br3 overflow-hidden">
      <img className="db w-100" alt={alt} src={image} />
      {allowEdit && (
        <div className="absolute bottom-0 right-0 ma2 flex justify-end bg-white-90 br2">
          <div
            role="button"
            tabIndex={0}
            className="material-icons pointer pa1"
            title="Excluir"
            onClick={handleDeleteTributeAction}
            onKeyDown={handleDeleteTributeAction}
          >
            delete
          </div>
        </div>
      )}
    </section>
  )
}

export default ImageOnly
