import React from 'react'

const FormInlineError = ({ ...props }) => {
  const { error, position, style } = props
  let iconPosition = 'top-0 bottom-0'
  if (position) {
    switch (position) {
      case 'top':
        iconPosition = 'top-0'
        break
      case 'bottom':
        iconPosition = 'bottom-0'
        break
      default:
        iconPosition = 'top-0 bottom-0'
        break
    }
  }

  return (
    <div
      data-id="form-inline-error"
      className={`absolute right-0  flex items-center ph1 z-max ${iconPosition}`}
    >
      <div className="relative hide-child">
        <span className="material-icons red" style={{ cursor: 'default' }}>
          info_outline
        </span>
        <div
          className={`child absolute context pa2 f7 tr right-0 mt1 ${
            style ? 'ws-normal' : 'nowrap'
            }`}
          data-pos="right"
          style={style}
        >
          <div className="red">{error}</div>
        </div>
      </div>
    </div>
  )
}

export default FormInlineError
